// app/javascript/controllers/refresh_stock_controller.js
import { Controller } from "@hotwired/stimulus"
import { I18n } from 'i18n-js'
import translations from '../translations.json'

const i18n = new I18n(translations)
const userLocale = document
    .querySelector("meta[name='user-locale']")
    .getAttribute('content')
i18n.locale = userLocale

export default class extends Controller {
  static targets = ["button"]
  static values = {
    restaurants: String
  }

  connect() {
    this.loadingModal = new bootstrap.Modal(document.getElementById('loadingModal'))
  }

  async refreshStock(event) {
    event.preventDefault()

    // Afficher le modal de chargement
    const loadingText = document.querySelector('#loadingModal .modal-body p')
    loadingText.textContent = i18n.t('i18njs.Starting data refresh...')
    this.loadingModal.show()

    try {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      const restaurants = JSON.parse(this.restaurantsValue)
      const restaurantIds = restaurants.map(r => r.id)

      // Construire l'URL
      let url = '/workers/launch'
      if (document.body.dataset.locale) {
        url = `/${document.body.dataset.locale}${url}`
      }

      // Préparer les données
      const formData = new FormData()
      formData.append('worker', 'turnover')
      formData.append('start_date', new Date().toISOString().split('T')[0])
      formData.append('end_date', new Date().toISOString().split('T')[0])
      formData.append('ratio', '1')
      formData.append('skip_aggregation', 'false')
      restaurantIds.forEach(id => {
        formData.append('restaurant_ids[]', id)
      })

      // Envoyer la requête
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': csrfToken,
          'Accept': 'application/json',
          'X-Requested-With': 'XMLHttpRequest'
        },
        body: formData
      })

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      // Attendre un délai fixe basé sur le nombre de restaurants
      // Estimation de 5 secondes par restaurant
      const delayPerRestaurant = 8000 // 8 secondes
      const totalDelay = Math.max(restaurantIds.length * delayPerRestaurant, 10000) // minimum 10 secondes

      // Mettre à jour le texte du modal
      loadingText.textContent = i18n.t('i18njs.Processing data... This might take a few seconds')

      // Attendre le délai calculé
      await new Promise(resolve => {
        let remainingTime = Math.floor(totalDelay / 1000)

        const interval = setInterval(() => {
          remainingTime--
          loadingText.textContent = i18n.t('i18njs.Processing data... Please wait') + ' ' +
            remainingTime + ' ' + i18n.t('i18njs.seconds')

          if (remainingTime <= 0) {
            clearInterval(interval)
            resolve()
          }
        }, 1000)
      })

      // Recharger la page
      loadingText.textContent = i18n.t('i18njs.Refreshing page...')
      window.location.reload()

    } catch (error) {
      console.error('Error:', error)
      this.loadingModal.hide()
      alert(i18n.t('An error occurred while refreshing stock data'))
    }
  }
}
