import { Controller } from '@hotwired/stimulus'
import flatpickr from 'flatpickr'
import { I18n } from 'i18n-js'
import translations from '../translations.json'

const i18n = new I18n(translations)
const userLocale = document
    .querySelector("meta[name='user-locale']")
    .getAttribute('content')
i18n.locale = userLocale

// Connects to data-controller="form-recipe"
export default class extends Controller {
    static targets = [
        'destroyLink',
        'ingredientList',
        'netPercentage',
        'quantityInput',
        'nbOfPieces',
        'pricePerPortion',
        'recipeFields',
        'pricePerKg',
        'percentCheckbox',
        'totalItemWeight',
        'totalCellWeight',
        'priceIn',
        'priceOut',
        'vat',
        'vatInInput',
        'vatOutInput',
        'vatIn',
        'vatOut',
        'priceGeneralInfos',
        'recipeCategory',
        'ingredientFields',
        'totalCell',
        'totalItemCost',
        'percentCostCategory',
        'priceAdvisedIn',
        'priceAdvisedOut',
    ]
    indexCounter = 1000

    connect() {
        console.log('form-recipe connected')
        // this.updateAllSpanPrices()
        this.updatePriceAdvised()
    }

    async vatRateChange(event) {
        const vatRate = event.currentTarget.value
        const vatIn = this.vatInInputTarget.value
        const vatOut = this.vatOutInputTarget.value
        const recipeId = this.priceGeneralInfosTarget.dataset.recipeId
        let url = `/recipes/${recipeId}`
        if (document.body.dataset.locale) {
            url = `/${document.body.dataset.locale}${url}`
        }
        try {
            const response = await fetch(url, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'X-CSRF-Token': document.querySelector(
                        'meta[name=csrf-token]'
                    ).content,
                },
                body: JSON.stringify({
                    vat_in: vatIn,
                    vat_out: vatOut,
                    id: recipeId,
                }),
            })

            const data = await response.json()
            this.priceGeneralInfosTarget.dataset.vatIn = vatIn
            this.priceGeneralInfosTarget.dataset.vatOut = vatOut
            this.vatInTarget.textContent = `Price IN (${vatIn}%)`
            this.vatOutTarget.textContent = `Price OUT (${vatOut}%)`
        } catch (error) {
            console.error('Erreur:', error)
        }
        this.updatePriceAdvised()
    }

    updatePriceAdvised() {
        const costPrice = parseFloat(
            this.pricePerPortionTarget.dataset.costPerPortion
        )

        const costPercent = parseFloat(
            this.priceGeneralInfosTarget.dataset.costPercent
        )
        const vatIn =
            parseFloat(this.priceGeneralInfosTarget.dataset.vatIn) / 100
        const vatOut =
            parseFloat(this.priceGeneralInfosTarget.dataset.vatOut) / 100

        const priceAdvisedIn = (
            (costPrice / costPercent) *
            (1 + vatIn)
        ).toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' })
        const priceAdvisedOut = (
            (costPrice / costPercent) *
            (1 + vatOut)
        ).toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' })

        this.priceAdvisedOutTarget.textContent = priceAdvisedOut.replace(
            /([\d,]+)\s€/,
            '€$1'
        )
        this.priceAdvisedInTarget.textContent = priceAdvisedIn.replace(
            /([\d,]+)\s€/,
            '€$1'
        )
        // this.updateAllSpanPrices();
        this.calculateMarginCostPercent()
    }

    addIngredients() {
        const selectedIngredients = Array.from(
            document.querySelectorAll(
                ".modal-body .form-check-input[type='checkbox']:checked"
            )
        )
        const ingredientFields = this.ingredientFieldsTarget

        selectedIngredients.forEach((checkbox) => {
            const ingredientId = checkbox.value
            const ingredientName = checkbox.dataset.itemIngredientName
            const unitMeasure = checkbox.dataset.unitMeasure
            const unitWeight = parseFloat(checkbox.dataset.unitWeight) || 0
            const recipeId = this.element.dataset.recipeId

            checkbox.checked = false

            let url = `/recipes/${recipeId}/add_ingredient`
            if (document.body.dataset.locale) {
                url = `/${document.body.dataset.locale}${url}`
            }

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': document.querySelector(
                        'meta[name=csrf-token]'
                    ).content,
                },
                body: JSON.stringify({ ingredient_id: ingredientId }),
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.status === 'success') {
                        // Ajouter la ligne au tableau HTML pour l'affichage
                        const newRow = ingredientFields.insertRow()
                        newRow.id = `ingredient-row-${data.item_id}`
                        const cell1 = newRow.insertCell(0)
                        const cell2 = newRow.insertCell(1)
                        const cell3 = newRow.insertCell(2)
                        const cell4 = newRow.insertCell(3)
                        const cell5 = newRow.insertCell(4)
                        const cell6 = newRow.insertCell(5)
                        const cell7 = newRow.insertCell(6)
                        const cell8 = newRow.insertCell(7)
                        const cell9 = newRow.insertCell(8)
                        const cell10 = newRow.insertCell(9)

                        cell1.innerText = ingredientId

                        const hiddenRecipeIngredientId = document.createElement('input')
                        hiddenRecipeIngredientId.type = 'hidden'
                        hiddenRecipeIngredientId.name = `recipe[recipe_ingredients_attributes][${this.indexCounter}][id]`
                        hiddenRecipeIngredientId.value = data.item_id
                        cell1.appendChild(hiddenRecipeIngredientId)

                        const hiddenIngredientIdInput = document.createElement('input')
                        hiddenIngredientIdInput.type = 'hidden'
                        hiddenIngredientIdInput.name = `recipe[recipe_ingredients_attributes][${this.indexCounter}][ingredient_id]`
                        hiddenIngredientIdInput.value = ingredientId
                        cell1.appendChild(hiddenIngredientIdInput)

                        const groupInput = document.createElement('input')
                        groupInput.type = 'text'
                        groupInput.className = 'form-control'
                        groupInput.placeholder = 'Organize by groups'
                        groupInput.name = `recipe[recipe_ingredients_attributes][${this.indexCounter}][group]`
                        groupInput.id = `recipe_recipe_ingredients_attributes_${this.indexCounter}_group`
                        cell2.appendChild(groupInput)

                        cell3.className = 'text-start align-middle'

                        // Capitalisation et ajout du texte
                        const capitalizedIngredientName =
                            ingredientName.charAt(0).toUpperCase() +
                            ingredientName.slice(1)
                        const textNode = document.createTextNode(
                            capitalizedIngredientName
                        )
                        cell3.appendChild(textNode)

                        // Ajout du lien avec l'icône
                        const linkElement = document.createElement('a')
                        linkElement.href = `/ingredients/${data.ingredient_item_id}/edit`
                        linkElement.target = '_blank'
                        linkElement.rel = 'noopener noreferrer'
                        linkElement.tabIndex = '-1'

                        const iconElement = document.createElement('i')
                        iconElement.className = 'fa-regular fa-eye ms-2'
                        linkElement.appendChild(iconElement)
                        cell3.appendChild(linkElement)

                        // Modification de la cellule quantity (cell4)
                        cell4.className = 'd-flex align-items-center'
                        const quantityInput = document.createElement('input')
                        quantityInput.type = 'number'
                        quantityInput.className =
                            'form-control no-spin text-center'
                        quantityInput.placeholder = 'Qté en kg'
                        quantityInput.min = 0
                        quantityInput.name = `recipe[recipe_ingredients_attributes][${this.indexCounter}][quantity]`
                        quantityInput.id = `recipe_recipe_ingredients_attributes_${this.indexCounter}_quantity`
                        quantityInput.value = 0
                        quantityInput.dataset.action =
                            'change->form-recipe#updateQtyIngredientItem'
                        quantityInput.dataset.formRecipeTarget = 'quantityInput'
                        quantityInput.dataset.ingredientItemId = data.item_id
                        quantityInput.dataset.itemId = data.item_id
                        quantityInput.dataset.itemType = 'ingredient'
                        cell4.appendChild(quantityInput)

                        const unitQtySpan = document.createElement('span');
                        unitQtySpan.className = `fw-bold ms-1${unitMeasure === 'PC' ? ' text-danger' : ''}`;
                        const unitSmall = document.createElement('small');
                        unitSmall.textContent = unitMeasure === 'PC' ? '(PC)' : '(KG)';
                        unitQtySpan.appendChild(unitSmall);
                        cell4.appendChild(unitQtySpan);

                        const percentInput = document.createElement('input')
                        percentInput.type = 'number'
                        percentInput.className =
                            'form-control no-spin text-center'
                        percentInput.min = 0
                        percentInput.dataset.formRecipeTarget = 'netPercentage'
                        percentInput.dataset.action =
                            'change->form-recipe#updatePercentNetWeight'
                        percentInput.dataset.ingredientItemId = data.item_id
                        percentInput.value = data.net_weight || 50.0 // Valeur par défaut de 50.0 si non spécifié
                        percentInput.name = `recipe[recipe_ingredients_attributes][${this.indexCounter}][net_percentage]`
                        percentInput.id = `recipe_recipe_ingredients_attributes_${this.indexCounter}_net_percentage`
                        cell5.appendChild(percentInput)

                        // Modification de la cellule total weight (cell6)
                        cell6.className = 'd-flex align-items-center'
                        cell6.dataset.formRecipeTarget = 'totalCellWeight'
                        cell6.dataset.ingredientItemId = data.item_id

                        const weightInput = document.createElement('input')
                        weightInput.className = 'form-control text-center'
                        weightInput.dataset.action =
                            'change->form-recipe#updateNetWeight'
                        weightInput.dataset.itemType = 'ingredient'
                        weightInput.dataset.itemId = data.item_id
                        weightInput.dataset.ingredientItemId = data.item_id
                        weightInput.value = '0' // Valeur par défaut
                        cell6.appendChild(weightInput)

                        // Ajout de l'avertissement pour PC si nécessaire
                        if (unitMeasure === 'PC' && !unitWeight) {
                            const warningDiv = document.createElement('div')
                            const warningSpan = document.createElement('span')
                            warningSpan.setAttribute(
                                'data-bs-toggle',
                                'tooltip'
                            )
                            warningSpan.setAttribute(
                                'data-bs-placement',
                                'right'
                            )
                            warningSpan.setAttribute(
                                'title',
                                i18n.t(
                                    'i18njs.Be careful, you have not set a unit weight for this ingredient with a unit of measurement instead, so go to the ingredient sheet to edit the unit weight, and refresh this page'
                                )
                            )

                            const warningIcon = document.createElement('i')
                            warningIcon.className =
                                'fa-solid fa-triangle-exclamation text-danger ms-2 fa-xl'

                            warningSpan.appendChild(warningIcon)
                            warningDiv.appendChild(warningSpan)
                            cell6.appendChild(warningDiv)
                        }

                        // Modification de l'affichage du prix unitaire (cell7)
                        let unitPrice = data.unit_price / 100
                        let formattedUnitPrice = unitPrice.toLocaleString(
                            'fr-FR',
                            {
                                style: 'currency',
                                currency: 'EUR',
                            }
                        )
                        formattedUnitPrice = formattedUnitPrice.replace(
                            /([\d,]+)\s€/,
                            '€$1'
                        )

                        // Création du span pour l'unité de mesure avec style conditionnel
                        const unitSpan = document.createElement('span')
                        unitSpan.className =
                            unitMeasure === 'PC' ? 'text-danger fw-bold' : ''
                        unitSpan.textContent = ` (${data.unit_measure})`

                        cell7.textContent = formattedUnitPrice
                        cell7.appendChild(unitSpan)

                        let total = (0).toLocaleString('fr-FR', {
                            style: 'currency',
                            currency: 'EUR',
                        })
                        cell8.innerText = total.replace(/([\d,]+)\s€/, '€$1')
                        cell8.dataset.formRecipeTarget = 'totalCell'
                        cell8.dataset.ingredientItemId = data.item_id

                        const destroyLink = document.createElement('a')
                        destroyLink.innerHTML =
                            '<i class="fa-solid fa-trash"></i>'
                        destroyLink.href = '#'
                        destroyLink.className = 'update-button'
                        destroyLink.dataset.formRecipeTarget = 'destroyLink'
                        destroyLink.dataset.action =
                            'click->form-recipe#deleteItem'
                        destroyLink.dataset.ingredientItemId =
                            data.ingredient_item_id
                        destroyLink.dataset.ingredientId = ingredientId
                        destroyLink.dataset.recipeId = recipeId
                        destroyLink.dataset.itemId = data.ingredient_item_id
                        destroyLink.dataset.itemType = 'ingredient'
                        destroyLink.dataset.confirm = 'Are you sure ?'
                        cell9.appendChild(destroyLink)

                        const hiddenInput = document.createElement('input')
                        hiddenInput.type = 'hidden'
                        hiddenInput.name = `recipe[recipe_ingredients_attributes][${this.indexCounter}][link_to_net_percent_ingredient]`
                        hiddenInput.value = '0'
                        hiddenInput.autocomplete = 'off'
                        cell10.appendChild(hiddenInput)

                        // Ensuite le checkbox visible
                        const checkBox = document.createElement('input')
                        checkBox.type = 'checkbox'
                        checkBox.dataset.formRecipeTarget = 'percentCheckbox'
                        checkBox.dataset.action =
                            'click->form-recipe#updateValueOfCheckbox'
                        checkBox.dataset.ingredientItemId = data.item_id
                        checkBox.checked = true
                        checkBox.value = '1'
                        checkBox.name = `recipe[recipe_ingredients_attributes][${this.indexCounter}][link_to_net_percent_ingredient]`
                        checkBox.id = `recipe_recipe_ingredients_attributes_${this.indexCounter}_link_to_net_percent_ingredient`
                        cell10.appendChild(checkBox)

                        const correspondingDiv = document.querySelector(
                            `[data-ingredient-modal-id="ingredientModalDiv_${ingredientId}"]`
                        )
                        if (correspondingDiv) {
                            correspondingDiv.classList.add('d-none')
                        }

                        // Initialiser les tooltips Bootstrap
                        const tooltips = document.querySelectorAll(
                            '[data-bs-toggle="tooltip"]'
                        )
                        tooltips.forEach((tooltip) => {
                            new bootstrap.Tooltip(tooltip)
                        })
                    }
                })
                .catch((error) => {
                    console.error('Error:', error)
                })

            this.indexCounter++
        })
        this.updateModalList()
    }

    updateQtyIngredientItem(event) {
        const itemType = event.currentTarget.dataset.itemType
        const itemId = event.currentTarget.dataset.itemId
        const quantity = event.currentTarget.value
        const targetAttribute =
            itemType === 'ingredient' ? 'ingredientItemId' : 'recipeItemId'
        const totalCell = this.totalCellTargets.find(
            (cell) => cell.dataset[targetAttribute] == itemId
        )
        const totalCellWeight = this.totalCellWeightTargets.find(
            (cell) => cell.dataset[targetAttribute] == itemId
        )
        let url =
            itemType === 'ingredient'
                ? `/recipe_ingredients/${itemId}`
                : `/recipe_sub_recipes/${itemId}`
        if (document.body.dataset.locale) {
            url = `/${document.body.dataset.locale}${url}`
        }
        fetch(url, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')
                    .content,
            },
            body: JSON.stringify({ id: itemId, quantity: quantity }),
        })
            .then((response) => response.json())
            .then((data) => {
                const unitPrice = parseFloat(data.unit_price / 100)
                let total

                if (data.unit_measure === 'PC') {
                    if (
                        data.unit_weight !== null &&
                        data.unit_weight !== undefined &&
                        parseFloat(data.unit_weight) > 0
                    ) {
                        total = unitPrice * quantity
                    } else {
                        total = 0
                    }
                } else {
                    total = unitPrice * quantity
                }
                let formattedTotal = total.toLocaleString('fr-FR', {
                    style: 'currency',
                    currency: 'EUR',
                })
                formattedTotal = formattedTotal.replace(/([\d,]+)\s€/, '€$1')
                totalCell.textContent = formattedTotal
                const inputInsideTotalCellWeight = totalCellWeight
                    ? totalCellWeight.querySelector('input')
                    : null

                if (inputInsideTotalCellWeight) {
                    inputInsideTotalCellWeight.value = data.total_weight
                } else {
                    totalCellWeight.textContent = data.total_weight
                }
                this.totalItemCostTarget.value = data.total_item_cost
                this.totalItemWeightTarget.value = data.total_item_weight
                this.pricePerKgTarget.value = data.price_per_kg
                this.calculateTotalCost()
                this.updateNbOfPieces()
                this.updateListOfIngredients()
            })
            .catch((error) => {
                console.error('Error:', error)
            })
    }

    deleteItem(event) {
        event.preventDefault()
        if (!confirm(event.currentTarget.dataset.confirm)) {
            return
        }

        const itemId = event.currentTarget.dataset.itemId
        const itemType = event.currentTarget.dataset.itemType
        const targetAttribute =
            itemType === 'ingredient' ? 'ingredientItemId' : 'recipeItemId'
        const destroyCell = this.destroyLinkTargets.find(
            (cell) => cell.dataset[targetAttribute] == itemId
        )
        const recipeOrIngredientId =
            itemType === 'ingredient'
                ? destroyCell.dataset.ingredientId
                : destroyCell.dataset.subRecipeId
        let url =
            itemType === 'ingredient'
                ? `/recipe_ingredients/${itemId}`
                : `/recipe_sub_recipes/${itemId}`
        if (document.body.dataset.locale) {
            url = `/${document.body.dataset.locale}${url}`
        }
        fetch(url, {
            method: 'DELETE',
            headers: {
                'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')
                    .content,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status === 'success') {
                    // Supprimer la ligne du tableau
                    const correspondingDiv =
                        itemType === 'ingredient'
                            ? document.querySelector(
                                  `[data-ingredient-modal-id="ingredientModalDiv_${recipeOrIngredientId}"]`
                              )
                            : document.querySelector(
                                  `[data-recipe-modal-id="recipeModalDiv_${recipeOrIngredientId}"]`
                              )
                    if (correspondingDiv) {
                        correspondingDiv.classList.remove('d-none')
                    }
                    const row =
                        itemType === 'ingredient'
                            ? document.getElementById(
                                  `ingredient-row-${itemId}`
                              )
                            : document.getElementById(`recipe-row-${itemId}`)
                    if (row) {
                        row.remove()
                    }
                } else {
                    console.error('Erreur lors de la suppression')
                }
                this.pricePerKgTarget.value = data.price_per_kg
                this.totalItemWeightTarget.value = data.total_item_weight
                this.calculateTotalCost()
                this.updateModalList()
            })
            .catch((error) => {
                console.error('Erreur:', error)
            })
        this.updateNbOfPieces()
        this.updateListOfIngredients()
    }

    updateModalList() {
        const recipeId = this.element.dataset.recipeId
        let url = `/recipes/${recipeId}/edit`
        if (document.body.dataset.locale) {
            url = `/${document.body.dataset.locale}${url}`
        }
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')
                    .content,
            },
        })
            .then((response) => response.text())
            .then((html) => {
                const parser = new DOMParser()
                const doc = parser.parseFromString(html, 'text/html')

                // Mettre à jour le modal des recettes
                const recipeModalContent =
                    doc.querySelector('#SubRecipeModal').outerHTML
                document.querySelector('#SubRecipeModal').outerHTML =
                    recipeModalContent

                // Mettre à jour le modal des ingrédients (ajustez l'ID selon votre mise en page)
                const ingredientModalContent =
                    doc.querySelector('#ingredientModal').outerHTML
                document.querySelector('#ingredientModal').outerHTML =
                    ingredientModalContent
            })
            .catch((error) => {
                console.error('Erreur:', error)
            })
    }

    calculateMarginCostPercent() {
        if (this.priceOutTarget.value.includes('.')) {
            this.priceOutTarget.value = this.priceOutTarget.value.replace(
                '.',
                ','
            )
        }
        if (this.priceInTarget.value.includes('.')) {
            this.priceInTarget.value = this.priceInTarget.value.replace(
                '.',
                ','
            )
        }
        const priceOut = parseFloat(
            this.priceOutTarget.value.replace('€', '').replace(',', '.')
        )
        const priceIn = parseFloat(
            this.priceInTarget.value.replace('€', '').replace(',', '.')
        )
        const vatIn =
            parseFloat(this.priceGeneralInfosTarget.dataset.vatIn) / 100
        const vatOut =
            parseFloat(this.priceGeneralInfosTarget.dataset.vatOut) / 100

        const costPrice = this.pricePerPortionTarget.dataset.costPerPortion
        const relatedSpanOut = document.querySelector(
            `[data-related-input="price_out_input"]`
        )
        const relatedSpanIn = document.querySelector(
            `[data-related-input="price_in_input"]`
        )
        const marginCostPercentOut =
            (costPrice / (priceOut / (1 + vatOut))) * 100
        const marginCostPercentIn = (costPrice / (priceIn / (1 + vatIn))) * 100

        if (relatedSpanOut && relatedSpanIn) {
            this.updateSpanStyle(marginCostPercentOut, relatedSpanOut)
            this.updateSpanStyle(marginCostPercentIn, relatedSpanIn)
        }
    }

    calculateTotalCost() {
        const totalCost = Array.from(this.totalCellTargets).reduce(
            (acc, cell) => {
                const costText = cell.textContent
                    .replace('€', '')
                    .trim()
                    .replace(/,/g, '.')
                const cost = parseFloat(costText) || 0

                return acc + cost
            },
            0
        )
        let formattedTotal = totalCost.toLocaleString('fr-FR', {
            style: 'currency',
            currency: 'EUR',
        })
        formattedTotal = formattedTotal.replace(/([\d,]+)\s€/, '€$1')
        this.totalItemCostTarget.value = formattedTotal
        this.priceGeneralInfosTarget.dataset.costPrice = totalCost * 100
        this.updatePriceAdvised()
        this.updateAllSpanPrices()
    }

    updateSpanStyle(percent, span) {
        const costPercent =
            this.priceGeneralInfosTarget.dataset.costPercent * 100
        const redColorLimit = parseFloat(
            this.priceGeneralInfosTarget.dataset.redLimitColor
        )
        const orangeColorLimit = parseFloat(
            this.priceGeneralInfosTarget.dataset.orangeLimitColor
        )
        const darkGreenColorLimit = parseFloat(
            this.priceGeneralInfosTarget.dataset.darkGreenLimitColor
        )
        const redColor = this.priceGeneralInfosTarget.dataset.redColor
        const orangeColor = this.priceGeneralInfosTarget.dataset.orangeColor
        const greenColor = this.priceGeneralInfosTarget.dataset.greenColor
        const darkGreenColor =
            this.priceGeneralInfosTarget.dataset.darkGreenColor

        if (!isFinite(percent)) {
            span.textContent = '0%'
            span.style.color = 'black'
            span.style.fontWeight = 'normal'
        } else {
            const difference = percent - costPercent
            const percentageDifference = (difference / costPercent) * 100
            span.textContent = `${percent.toFixed(1)}% ( ${difference.toFixed(0)}% )`

            if (percentageDifference > redColorLimit) {
                span.style.color = redColor
                span.style.fontWeight = 'bold'
            } else if (percentageDifference > orangeColorLimit) {
                span.style.color = orangeColor
                span.style.fontWeight = 'bold'
            } else if (
                percentageDifference < orangeColorLimit &&
                percentageDifference > darkGreenColorLimit
            ) {
                span.style.color = greenColor
                span.style.fontWeight = 'bold'
            } else if (percentageDifference < darkGreenColorLimit) {
                span.style.color = darkGreenColor
                span.style.fontWeight = 'bold'
            } else {
                span.style.color = 'black'
                span.style.fontWeight = 'normal'
            }
        }
    }

    updateAllSpanPrices() {
        const spans = document.querySelectorAll('.margin-span')
        spans.forEach((span) => {
            const inputId = span.getAttribute('data-related-input')
            const inputElement = document.getElementById(inputId)
            const vatType = inputElement.dataset.vatType
            let vat
            if (vatType === 'in') {
                vat =
                    parseFloat(this.priceGeneralInfosTarget.dataset.vatIn) / 100
            } else if (vatType === 'out') {
                vat =
                    parseFloat(this.priceGeneralInfosTarget.dataset.vatOut) /
                    100
            }
            // const vat = parseFloat(inputElement.dataset.vat);
            const costPrice = this.pricePerPortionTarget.dataset.costPerPortion

            // Remplacer la virgule par un point si nécessaire
            let inputValue = inputElement.value.replace(',', '.')

            // Convertir la valeur en float
            inputValue = parseFloat(inputValue)

            const marginCostPercent =
                (costPrice / (inputValue / (1 + vat))) * 100

            if (!isFinite(marginCostPercent)) {
                span.textContent = '0%'
            } else {
                inputElement.value = inputElement.value.replace('.', ',')
                this.updateSpanStyle(marginCostPercent, span)
            }
        })
    }

    //  ne pas retirer, se lance au save du form
    convertCommaToDot() {
        let priceInValue = this.priceInTarget.value
        let priceOutValue = this.priceOutTarget.value
        let pricePerKgValue = this.pricePerKgTarget.value
        let pricePerPortionValue = this.pricePerPortionTarget.value
        if (
            priceInValue.includes(',') ||
            priceOutValue.includes(',') ||
            pricePerKgValue.includes(',') ||
            pricePerPortionValue.includes(',')
        ) {
            this.priceInTarget.value = priceInValue.replace(',', '.')
            this.priceOutTarget.value = priceOutValue.replace(',', '.')
            this.pricePerKgTarget.value = pricePerKgValue.replace(',', '.')
            this.pricePerPortionTarget.value = pricePerPortionValue.replace(
                ',',
                '.'
            )
        }
    }

    updatePercentNetWeight(event) {
        const ingredientItemId = event.currentTarget.dataset.ingredientItemId
        const netWeight = event.currentTarget.value
        const recipeId = this.element.dataset.recipeId
        const totalCell = this.totalCellWeightTargets.find(
            (cell) => cell.dataset.ingredientItemId == ingredientItemId
        )
        const checkboxPercent = this.percentCheckboxTargets.find(
            (cell) => cell.dataset.ingredientItemId == ingredientItemId
        )

        const quantityInput = this.quantityInputTargets.find(
            (input) => input.dataset.ingredientItemId == ingredientItemId
        )
        let url = `/recipe_ingredients/${ingredientItemId}`
        if (document.body.dataset.locale) {
            url = `/${document.body.dataset.locale}${url}`
        }
        fetch(url, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')
                    .content,
            },
            body: JSON.stringify({
                id: ingredientItemId,
                net_weight: netWeight,
                cash_register_item_id: recipeId,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                // totalCell.innerText = data.total_weight;
                this.totalItemWeightTarget.value = data.total_item_weight
                this.pricePerKgTarget.value = data.price_per_kg
                if (
                    parseFloat(netWeight) !== data.ingredient_percent_net_weight
                ) {
                    checkboxPercent.checked = false
                } else {
                    checkboxPercent.checked = true
                }

                const inputInsideTotalCellWeight = totalCell
                    ? totalCell.querySelector('input')
                    : null

                if (inputInsideTotalCellWeight) {
                    inputInsideTotalCellWeight.value = data.total_weight
                } else {
                    console.error(
                        'Input for total weight not found for item id:',
                        data.ingredient_item_id
                    )
                }
                this.updateListOfIngredients()
            })
            .catch((error) => {
                console.error('Error:', error)
            })
    }

    updateValueOfCheckbox(event) {
        const ingredientItemId = event.currentTarget.dataset.ingredientItemId
        const checkboxValue = event.currentTarget.checked
        const percentCell = this.netPercentageTargets.find(
            (cell) => cell.dataset.ingredientItemId == ingredientItemId
        )
        let url = `/recipe_ingredients/${ingredientItemId}`
        if (document.body.dataset.locale) {
            url = `/${document.body.dataset.locale}${url}`
        }
        fetch(url, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')
                    .content,
            },
            body: JSON.stringify({
                id: ingredientItemId,
                checkbox_value: checkboxValue,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.link_to_percent_value === true) {
                    percentCell.value = data.ingredient_percent_value
                    const changeEvent = new Event('change', {
                        bubbles: true, // Permet à l'événement de remonter jusqu'aux ancêtres
                        cancelable: true,
                    })
                    percentCell.dispatchEvent(changeEvent)
                }
            })
            .catch((error) => {
                console.error('Error:', error)
            })
    }

    addSubRecipes() {
        const selectedRecipes = Array.from(
            document.querySelectorAll(
                ".modal-body .form-check-input[type='checkbox']:checked"
            )
        )
        const recipeFields = this.recipeFieldsTarget

        selectedRecipes.forEach((checkbox) => {
            const selectedRecipeId = checkbox.value
            const recipeName = checkbox.dataset.itemRecipeName
            const recipeId = this.element.dataset.recipeId
            checkbox.checked = false
            let url = `/recipes/${recipeId}/add_sub_recipe`
            if (document.body.dataset.locale) {
                url = `/${document.body.dataset.locale}${url}`
            }
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': document.querySelector(
                        'meta[name=csrf-token]'
                    ).content,
                },
                body: JSON.stringify({ selected_recipe_id: selectedRecipeId }),
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.status === 'success') {
                        // Ajouter la ligne au tableau HTML pour l'affichage
                        const newRow = recipeFields.insertRow()
                        newRow.id = `recipe-row-${data.recipe_item_id}`
                        const cell1 = newRow.insertCell(0)
                        const cell2 = newRow.insertCell(1)
                        const cell3 = newRow.insertCell(2)
                        const cell4 = newRow.insertCell(3)
                        const cell5 = newRow.insertCell(4)
                        const cell6 = newRow.insertCell(5)
                        const cell7 = newRow.insertCell(6)
                        const cell8 = newRow.insertCell(7)
                        const cell9 = newRow.insertCell(8)
                        const cell10 = newRow.insertCell(9)

                        cell1.innerText = selectedRecipeId

                        const groupInput = document.createElement('input')
                        groupInput.type = 'text'
                        groupInput.className = 'form-control'
                        groupInput.placeholder = 'Organize by groups'
                        cell2.appendChild(groupInput)

                        cell3.className = 'text-start align-middle'
                        const flexContainer = document.createElement('div')
                        flexContainer.className =
                            'd-flex justify-content-between'

                        const nameContainer = document.createElement('div')
                        // Capitalisation et ajout du texte
                        const capitalizedRecipeName =
                            recipeName.charAt(0).toUpperCase() +
                            recipeName.slice(1)
                        const textNode = document.createTextNode(
                            capitalizedRecipeName
                        )
                        nameContainer.appendChild(textNode) // Ajoutez le textNode au nameContainer

                        // Ajout du lien avec l'icône
                        const linkElement = document.createElement('a')
                        linkElement.href = `/recipes/${selectedRecipeId}/edit` // Modifiez le chemin selon vos besoins
                        linkElement.target = '_blank'
                        linkElement.rel = 'noopener noreferrer'
                        linkElement.tabIndex = '-1'

                        const iconElement = document.createElement('i')
                        iconElement.className = 'fa-regular fa-eye ms-2'
                        linkElement.appendChild(iconElement)
                        nameContainer.appendChild(linkElement) // Le lien est aussi dans le nameContainer

                        // Ajouter le conteneur du nom au conteneur flex
                        flexContainer.appendChild(nameContainer)

                        const badgeContainer = document.createElement('div')
                        // Création du badge
                        const badgeElement = document.createElement('span')
                        badgeElement.className = 'badge bg-info' // Ajoutez les classes nécessaires
                        badgeElement.textContent = 'SUB RECIPE' // Mettez le texte du badge
                        badgeContainer.appendChild(badgeElement)

                        // Ajouter le conteneur du badge au conteneur flex
                        flexContainer.appendChild(badgeContainer)

                        // Ajouter le conteneur flex à Cell3
                        cell3.appendChild(flexContainer)

                        const quantityInput = document.createElement('input')
                        quantityInput.type = 'number'
                        quantityInput.className =
                            'form-control no-spin text-center'
                        quantityInput.min = 0
                        quantityInput.value = 0
                        quantityInput.dataset.action =
                            'change->form-recipe#updateQtyIngredientItem'
                        quantityInput.dataset.recipeItemId = data.recipe_item_id
                        quantityInput.dataset.itemId = data.recipe_item_id
                        quantityInput.dataset.itemType = 'recipe'
                        cell4.appendChild(quantityInput)

                        cell6.innerText = 0
                        cell6.dataset.formRecipeTarget = 'totalCellWeight'
                        cell6.dataset.recipeItemId = data.recipe_item_id

                        let unitPrice = data.unit_price / 100
                        let formattedUnitPrice = unitPrice.toLocaleString(
                            'fr-FR',
                            { style: 'currency', currency: 'EUR' }
                        )
                        formattedUnitPrice = formattedUnitPrice.replace(
                            /([\d,]+)\s€/,
                            '€$1'
                        )
                        cell7.innerText = `${formattedUnitPrice} (${data.unit_measure})` // Remplacez par la vraie valeur unit_price renvoyée par votre API

                        let total = (0).toLocaleString('fr-FR', {
                            style: 'currency',
                            currency: 'EUR',
                        })
                        cell8.innerText = total.replace(/([\d,]+)\s€/, '€$1')
                        cell8.dataset.formRecipeTarget = 'totalCell'
                        cell8.dataset.recipeItemId = data.recipe_item_id

                        const destroyLink = document.createElement('a')
                        destroyLink.innerHTML =
                            '<i class="fa-solid fa-trash"></i>'
                        destroyLink.href = '#'
                        destroyLink.className = 'update-button'
                        destroyLink.dataset.formRecipeTarget = 'destroyLink'
                        destroyLink.dataset.action =
                            'click->form-recipe#deleteItem'
                        destroyLink.dataset.recipeItemId = data.recipe_item_id
                        destroyLink.dataset.itemId = data.recipe_item_id
                        destroyLink.dataset.subRecipeId = data.sub_recipe_id
                        // destroyLink.dataset.ingredientId = ingredientId;
                        destroyLink.dataset.recipeId = recipeId
                        destroyLink.dataset.itemType = 'recipe'
                        destroyLink.dataset.confirm = 'Are you sure ?'
                        cell9.appendChild(destroyLink)

                        const correspondingDiv = document.querySelector(
                            `[data-recipe-modal-id="recipeModalDiv_${selectedRecipeId}"]`
                        )
                        if (correspondingDiv) {
                            correspondingDiv.classList.add('d-none')
                        }
                    }
                })
                .catch((error) => {
                    console.error('Error:', error)
                })

            this.indexCounter++
        })
        this.updateModalList()
    }

    updateNbOfPieces() {
        const nbPieces = this.nbOfPiecesTarget.value
        const recipeId = this.element.dataset.recipeId
        let url = `/recipes/${recipeId}/update_nb_pieces`
        if (document.body.dataset.locale) {
            url = `/${document.body.dataset.locale}${url}`
        }
        fetch(url, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')
                    .content,
            },
            body: JSON.stringify({ id: recipeId, nb_pieces: nbPieces }),
        })
            .then((response) => response.json())
            .then((data) => {
                this.pricePerPortionTarget.value = data.price_per_portion
                this.pricePerPortionTarget.dataset.costPerPortion =
                    data.price_per_portion_value / 100
                this.calculateMarginCostPercent()
                this.updatePriceAdvised()
            })
            .catch((error) => {
                console.error('Error:', error)
            })
    }

    updateNetWeight(event) {
        // Récupérer l'élément `input` qui a déclenché l'événement
        const inputElement = event.currentTarget
        const newTotalWeightValue = inputElement.value.replace(',', '.')

        // Identifier la 'td' parente qui contient l'ID de l'élément
        const parentCell = inputElement.closest('td[data-ingredient-item-id]')
        if (!parentCell) {
            console.error(
                "Parent cell with 'data-ingredient-item-id' not found."
            )
            return
        }

        const ingredientItemId = parentCell.dataset.ingredientItemId

        // Trouver l'input de la quantité qui correspond à l'élément modifié
        const quantityInput = this.quantityInputTargets.find(
            (input) => input.dataset.ingredientItemId == ingredientItemId
        )
        if (!quantityInput) {
            console.error(
                'Quantity input not found for ingredient item id:',
                ingredientItemId
            )
            return
        }

        // Récupérer la valeur du pourcentage net liée à cet ingrédient spécifique
        const netPercentageInput = document.querySelector(
            `input[data-ingredient-item-id="${ingredientItemId}"][data-form-recipe-target="netPercentage"]`
        )
        if (!netPercentageInput) {
            console.error(
                'Net percentage input not found for ingredient item id:',
                ingredientItemId
            )
            return
        }

        const netPercentageValue = netPercentageInput.value

        // Calculer la nouvelle quantité et mettre à jour l'input de la quantité
        if (netPercentageValue && !isNaN(newTotalWeightValue)) {
            // Convertir en nombre et effectuer le calcul
            const newQuantity =
                (parseFloat(newTotalWeightValue) /
                    parseFloat(netPercentageValue)) *
                100
            quantityInput.value = newQuantity.toFixed(3) // Arrondir à trois décimales
            const changeEvent = new Event('change', {
                bubbles: true, // Permet à l'événement de remonter jusqu'aux ancêtres
                cancelable: true,
            })

            // Déclencher l'événement sur quantityInput
            // Cela supposera que la méthode updateQtyIngredientItem est déjà associée
            // à l'écouteur d'événements de changement pour cet élément.
            quantityInput.dispatchEvent(changeEvent)
            this.updateListOfIngredients()
        }
    }

    updateListOfIngredients() {
        const recipeId = this.element.dataset.recipeId
        let url = `/recipes/${recipeId}/request_update_on_ingredient_list`
        if (document.body.dataset.locale) {
            url = `/${document.body.dataset.locale}${url}`
        }
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')
                    .content,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                this.ingredientListTarget.innerHTML = data.list_of_ingredients
            })
            .catch((error) => {
                console.error('Error:', error)
            })
    }
}
