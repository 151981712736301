import { Controller } from '@hotwired/stimulus'
import { I18n } from 'i18n-js'
import translations from '../translations.json'

const i18n = new I18n(translations)
const userLocale = document
    .querySelector("meta[name='user-locale']")
    .getAttribute('content')
i18n.locale = userLocale

// Connects to data-controller="chart-dashboard-turnover"
export default class extends Controller {
    static targets = ['time']

    connect() {
        console.log('highcharts turnover')
        this.setDefaultTime()
        if (typeof Highcharts === 'undefined') {
            document.addEventListener('DOMContentLoaded', () => {
                setTimeout(() => this.loadChart(), 100)
            })
        } else {
            this.loadChart()
        }
    }

    setDefaultTime() {
        const selectElement = this.element.querySelector('select')
        if (selectElement && selectElement.options.length > 0) {
            selectElement.options[0].selected = true
        }
    }

    updateChart() {
        const selectedPeriod = this.timeTarget.value
        this.chart.showLoading()
        let url = `/dashboard/call_turnover_by_restaurant_chart_service?period=${selectedPeriod}&format=json`
        if (document.body.dataset.locale) {
            url = `/${document.body.dataset.locale}${url}`
        }
        fetch(url, {
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')
                    .content,
                'X-User-Anonymize': document.querySelector('meta[name=user-anonymize]')?.content || 'false'
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok')
                }
                return response.json()
            })
            .then((data) => {
                this.chart.update({
                    xAxis: { categories: data.categories },
                    series: [
                        {
                            data: data.series_last_month.map(Number),
                            name: data.selected_period_minus_1_y,
                            type: 'column',
                            color: '#FF5733',
                        },
                        {
                            data: data.series_this_month.map(Number),
                            name: data.selected_period,
                            type: 'column',
                            color: '#4133ff',
                        },
                        {
                            data: data.average_ticket_last_month.map(Number),
                            name: i18n.t('i18njs.Average Ticket Excl VAT'),
                            type: 'line',
                            yAxis: 1,
                            color: '#FF5733',
                        },
                        {
                            data: data.average_ticket_this_month.map(Number),
                            name: i18n.t('i18njs.Average Ticket Excl VAT'),
                            type: 'line',
                            yAxis: 1,
                            color: '#4133ff',
                        },
                    ],
                })
                this.chart.hideLoading()
            })
            .catch((error) => {
                console.error('Error updating chart:', error)
            })
    }

    loadChart() {
        if (typeof Highcharts === 'undefined') {
            console.error('Highcharts not loaded')
            return
        }
        console.log('loadChart')
        const chartData = this.element.querySelector(
            '#chart-data-dashboard-turnover'
        )
        const categoriesData = JSON.parse(
            chartData.getAttribute('data-categories')
        )
        const seriesLastMonthData = JSON.parse(
            chartData.getAttribute('data-series-last-month')
        ).map(Number)
        const seriesThisMonthData = JSON.parse(
            chartData.getAttribute('data-series-this-month')
        ).map(Number)
        const averageTicketLastMonthData = JSON.parse(
            chartData.getAttribute('data-average-ticket-last-month')
        ).map(Number)
        const averageTicketThisMonthData = JSON.parse(
            chartData.getAttribute('data-average-ticket-this-month')
        ).map(Number)
        const monthLastMonth = chartData.getAttribute('data-month-last-month')
        const monthThisMonth = chartData.getAttribute('data-month-this-month')

        this.chart = Highcharts.chart('chart-data-dashboard-turnover', {
            chart: {
                zoomType: 'xy',
            },
            title: {
                text: null,
                // useHTML: false,
                // text: '<i class="fa-solid fa-chart-bar fa-2xl text-primary"></i> ' + "Turnover by restaurant",
            },
            xAxis: {
                categories: categoriesData, // Les noms des restaurants
                crosshair: true,
            },
            yAxis: [
                {
                    title: {
                        text: i18n.t('i18njs.Turnover (€)'),
                    },
                    min: 0,
                },
                {
                    title: {
                        text: i18n.t('i18njs.Average Ticket (€)'),
                    },
                    opposite: true,
                },
            ],
            tooltip: {
                shared: true,
                useHTML: true,
                formatter: function () {
                    let tooltipHtml =
                        '<span style="font-size:10px">' +
                        this.key +
                        '</span><table>'

                    tooltipHtml +=
                        '<tr><th></th><th style="padding:0 5px;">' +
                        i18n.t('i18njs.Turnover') +
                        '</th><th style="padding:0 5px;">' +
                        i18n.t('i18njs.Average Ticket') +
                        '</th></tr>'

                    const turnoverPoints = this.points.filter(
                        (point) => point.series.type === 'column'
                    )
                    const ticketPoints = this.points.filter(
                        (point) => point.series.type === 'line'
                    )

                    turnoverPoints.forEach((point, index) => {
                        tooltipHtml +=
                            '<tr><td style="color:' +
                            point.series.color +
                            ';padding:0">' +
                            point.series.name +
                            ': </td>' +
                            '<td style="padding:0 5px;"><b>' +
                            point.y.toLocaleString('fr-FR', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                                useGrouping: true,
                            }) +
                            ' €</b></td>' +
                            '<td style="padding:0 5px;border-left:1px solid #ddd;"><b>' +
                            ticketPoints[index].y.toLocaleString('fr-FR', {
                                minimumFractionDigits: 1,
                                maximumFractionDigits: 1,
                                useGrouping: true,
                            }) +
                            ' €</b></td></tr>'
                    })

                    tooltipHtml += '</table>'
                    return tooltipHtml
                },
            },

            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                },
                line: {
                    marker: {
                        enabled: false,
                    },
                },
            },
            series: [
                {
                    name: monthLastMonth,
                    data: seriesLastMonthData,
                    type: 'column',
                    color: '#FF5733',
                },
                {
                    name: monthThisMonth,
                    data: seriesThisMonthData,
                    type: 'column',
                    color: '#4133ff',
                },
                {
                    name: i18n.t('i18njs.Average Ticket Excl VAT'),
                    data: averageTicketLastMonthData,
                    type: 'line',
                    yAxis: 1,
                    color: '#FF5733',
                },
                {
                    name: i18n.t('i18njs.Average Ticket Excl VAT'),
                    data: averageTicketThisMonthData,
                    type: 'line',
                    yAxis: 1,
                    color: '#4133ff',
                },
            ],
            credits: {
                enabled: false,
            },
        })
    }
}
