import { Controller } from '@hotwired/stimulus'
import { I18n } from 'i18n-js'
import translations from '../translations.json'

const i18n = new I18n(translations)
const userLocale = document
    .querySelector("meta[name='user-locale']")
    .getAttribute('content')
i18n.locale = userLocale

export default class extends Controller {
    static targets = ['chart']

    connect() {
        this.orderData = JSON.parse(this.element.dataset.orders)
        this.salesData = JSON.parse(this.element.dataset.sales)
        this.internalData = JSON.parse(this.element.dataset.internal)
        this.adjustmentsData = JSON.parse(this.element.dataset.adjustments)
        this.restaurants = JSON.parse(this.element.dataset.restaurants)

        if (typeof Highcharts === 'undefined') {
            document.addEventListener('DOMContentLoaded', () => {
                setTimeout(() => this.initChart(), 100)
            })
        } else {
            this.initChart()
        }
    }

    initChart() {
        // Préparer les données pour chaque mois
        const monthlyMargins = this.calculateMonthlyMargins();
        this.renderChart(monthlyMargins);
    }

    calculateMonthlyMargins() {
      const monthlyData = {};

      this.orderData.forEach((order) => {
          const date = new Date(order.created_at);
          const monthKey = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
          if (!monthlyData[monthKey]) {
              monthlyData[monthKey] = { total: 0, restaurants: {} };
          }

          const restaurantName = this.restaurants.find((r) => r.id === order.restaurant_id)?.name || '???';
          if (!monthlyData[monthKey].restaurants[restaurantName]) {
              monthlyData[monthKey].restaurants[restaurantName] = {
                  purchases: 0,
                  sales: 0,
                  losses: 0,
                  marketing: 0,
                  staff_meal: 0,
                  adjustments: 0
              };
          }

          monthlyData[monthKey].restaurants[restaurantName].purchases += order.total;
      });

      // Même chose pour les ventes
      this.salesData.forEach((sale) => {
          const date = new Date(sale.created_at);
          const monthKey = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
          if (!monthlyData[monthKey]) {
              monthlyData[monthKey] = { total: 0, restaurants: {} };
          }

          const restaurantName = this.restaurants.find((r) => r.id === sale.restaurant_id)?.name || '???';
          if (!monthlyData[monthKey].restaurants[restaurantName]) {
              monthlyData[monthKey].restaurants[restaurantName] = {
                  purchases: 0,
                  sales: 0,
                  losses: 0,
                  marketing: 0,
                  staff_meal: 0,
                  adjustments: 0
              };
          }

          monthlyData[monthKey].restaurants[restaurantName].sales += sale.total;
      });

      // Données internes
      this.internalData.forEach((internal) => {
          const date = new Date(internal.created_at);
          const monthKey = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
          if (!monthlyData[monthKey]) {
              monthlyData[monthKey] = { total: 0, restaurants: {} };
          }

          const restaurantName = this.restaurants.find((r) => r.id === internal.restaurant_id)?.name || '???';
          if (!monthlyData[monthKey].restaurants[restaurantName]) {
              monthlyData[monthKey].restaurants[restaurantName] = {
                  purchases: 0,
                  sales: 0,
                  losses: 0,
                  marketing: 0,
                  staff_meal: 0,
                  adjustments: 0
              };
          }

          monthlyData[monthKey].restaurants[restaurantName][internal.turnover_category] += internal.total;
      });

      // Ajustements
      this.adjustmentsData.forEach((adj) => {
          const date = new Date(adj.date);
          const monthKey = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
          if (!monthlyData[monthKey]) {
              monthlyData[monthKey] = { total: 0, restaurants: {} };
          }

          if (!monthlyData[monthKey].restaurants[adj.restaurant_name]) {
              monthlyData[monthKey].restaurants[adj.restaurant_name] = {
                  purchases: 0,
                  sales: 0,
                  losses: 0,
                  marketing: 0,
                  staff_meal: 0,
                  adjustments: 0
              };
          }

          monthlyData[monthKey].restaurants[adj.restaurant_name].adjustments += adj.amount;
      });

      // Calculer les totaux et formatter les données
      const sortedMonths = Object.keys(monthlyData).sort();
      return {
          categories: sortedMonths.map((m) => {
              const [year, mm] = m.split('-');
              return `${mm}/${year.slice(2)}`;
          }),
          data: sortedMonths.map((m) => {
              const restaurants = monthlyData[m].restaurants;
              const total = Object.values(restaurants).reduce((sum, r) =>
                  sum + (r.sales - r.purchases - r.losses - r.marketing - r.staff_meal + r.adjustments), 0);

              return {
                  y: total / 100,
                  restaurants: restaurants
              };
          })
      };
  }

    renderChart(data) {

      const parseMonthYear = (monthYear) => {
        const [month, year] = monthYear.split('/')
        return new Date(`20${year}`, parseInt(month) - 1)
    }

    // Trier les données par date tout en gardant le format d'affichage
    const sortedIndexes = data.categories
        .map((cat, index) => ({index, date: parseMonthYear(cat)}))
        .sort((a, b) => a.date - b.date)
        .map(item => item.index);

    // Réorganiser les données selon l'ordre trié
    const sortedData = {
      categories: sortedIndexes.map(index => data.categories[index]),
      data: sortedIndexes.map(index => {
          const point = data.data[index];
          return {
              ...point,
              color: point.y >= 0 ? '#28a745' : '#dc3545',
              name: data.categories[index]
          };
      })
  };

      Highcharts.chart(this.chartTarget, {
          chart: {
              type: 'column'
          },
          title: {
              useHTML: true,
              text: `<i class="fa-solid fa-chart-column fa-2xl text-primary"></i> ${i18n.t('i18njs.Monthly Margins')}`
          },
          xAxis: {
              categories: sortedData.categories,
              title: {
                  text: i18n.t('i18njs.Month')
              }
          },
          yAxis: {
              title: {
                  text: '€'
              },
              labels: {
                  formatter: function() {
                      return Highcharts.numberFormat(this.value, 2) + ' €';
                  }
              }
          },
          tooltip: {
            useHTML: true,
            formatter: function() {
                const monthYear = this.point.name;
                let tooltip = `
                    <div class="container p-2" style="max-width: 500px">
                        <div class="row">
                            <div class="col-12">
                                <b>${monthYear}</b>
                                <table class="table table-sm mt-2 mb-1">
                                    <thead>
                                        <tr>
                                            <th scope="col">Restaurant</th>
                                            <th scope="col" class="text-end">Marge</th>
                                        </tr>
                                    </thead>
                                    <tbody class="small">`;

                const restaurants = this.point.options.restaurants;
                Object.entries(restaurants).forEach(([name, values]) => {
                    const margin = (values.sales - values.purchases - values.losses -
                                  values.marketing - values.staff_meal + values.adjustments) / 100;
                    const color = margin >= 0 ? 'text-success' : 'text-danger';
                    tooltip += `<tr>
                        <td>${name}</td>
                        <td class="text-end ${color}">${Highcharts.numberFormat(margin, 2)} €</td>
                    </tr>`;
                });

                tooltip += `
                                    </tbody>
                                </table>
                                <div class="text-muted small mb-0 px-1"
                                    style="
                                        font-size: 0.75rem;
                                        max-width: 100%;
                                        min-width: 250px; /* Largeur minimale pour éviter un cadre trop petit */
                                        max-width: 400px; /* Largeur maximale pour limiter la taille */
                                        overflow-wrap: break-word;
                                        word-break: break-word;
                                        white-space: normal;">
                                    * ${i18n.t('i18njs.Look at the monthly variation table, it will give you more details on the flows and the explanation of this margin')}
                                </div>
                            </div>
                        </div>
                    </div>`;
                return tooltip;
            }
        },
          series: [{
              name: i18n.t('i18njs.Profit Margin'),
              data: sortedData.data
          }],
          plotOptions: {
              column: {
                  colorByPoint: true
              }
          },
          credits: {
              enabled: false
          }
      });
  }
}
