import { Controller } from '@hotwired/stimulus'
import { I18n } from 'i18n-js'
import translations from '../translations.json'

const i18n = new I18n(translations)
const userLocale = document
    .querySelector("meta[name='user-locale']")
    .getAttribute('content')
i18n.locale = userLocale

// Connects to data-controller="chart-dashboard-productivity"
export default class extends Controller {
    static targets = ['time']

    connect() {
        console.log('chart dashboard productivity')
        this.setDefaultTime()
        if (typeof Highcharts === 'undefined') {
            document.addEventListener('DOMContentLoaded', () => {
                setTimeout(() => this.loadChart(), 100)
            })
        } else {
            this.loadChart()
        }
        this.updateChart()
    }

    setDefaultTime() {
        const selectElement = this.element.querySelector('select')
        if (selectElement && selectElement.options.length > 0) {
            selectElement.options[0].selected = true
        }
    }

    updateChart() {
        const selectedPeriod = this.timeTarget.value
        this.chart.showLoading()
        let url = `/dashboard/call_chart_productivity_service?period=${selectedPeriod}&format=json`

        if (document.body.dataset.locale) {
            url = `/${document.body.dataset.locale}${url}`
        }

        fetch(url, {
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')
                    .content,
                'X-User-Anonymize': document.querySelector('meta[name=user-anonymize]')?.content || 'false'
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok')
                }
                return response.json()
            })
            .then((data) => {
                const restaurants = data.restaurants.map(
                    (restaurant, index) => ({
                        name: restaurant.name,
                        productivity: restaurant.productivity,
                        cost_per_hour: restaurant.cost_per_hour,
                        total_hours: restaurant.total_hours,
                        student_hours: restaurant.student_hours_percentage,
                    })
                )

                this.chart.update({
                    xAxis: { categories: data.periods }, // Périodes = jours ou semaines
                    series: data.restaurants.map((restaurant) => ({
                        name: restaurant.name,
                        data: restaurant.productivity, // Productivité sur les jours ou semaines
                    })),
                    tooltip: {
                        useHTML: true,
                        shared: true,
                        formatter: function () {
                            const index = this.points[0].point.index
                            let tooltipHtml = `<span class="h5 mb-2">${data.periods[index]}</span><br/><table class="table table-striped table-bordered table-hover table-sm">`
                            tooltipHtml += `<thead><tr><th>Restaurant</th><th>${i18n.t('i18njs.Productivity')}</th><th>${i18n.t('i18njs.Cost per Hour')}</th><th>${i18n.t('i18njs.Total Hours')}</th><th>${i18n.t('i18njs.Student Hours')}</th></tr></thead><tbody>`

                            restaurants.forEach((restaurant) => {
                                tooltipHtml += `
                                <tr>
                                    <td class="text-end fw-bold text-danger">${restaurant.name}</td>
                                    <td class="text-end">${restaurant.productivity[index] || 0} €/h</td>
                                    <td class="text-end">${restaurant.cost_per_hour[index] || 0} €/h</td>
                                    <td class="text-end">${Math.round(restaurant.total_hours[index]) || 0} h</td>
                                    <td class="text-end">${Math.round(restaurant.student_hours[index]) || 0}%</td>
                                </tr>`
                            })

                            tooltipHtml += '</tbody></table>'
                            return tooltipHtml
                        },
                    },
                })
                this.chart.hideLoading()
            })
            .catch((error) => {
                console.error('Error updating chart:', error)
            })
    }

    loadChart() {
        if (typeof Highcharts === 'undefined') {
            console.error('Highcharts not loaded')
            return
        }
        const chartData = this.element.querySelector(
            '#chart-data-dashboard-productivity'
        )
        const periodsData = JSON.parse(chartData.getAttribute('data-periods'))
        const productivityData = JSON.parse(
            chartData.getAttribute('data-productivity')
        )
        const costPerHourData = JSON.parse(
            chartData.getAttribute('data-cost-per-hour')
        )
        const totalHoursData = JSON.parse(
            chartData.getAttribute('data-total-hours')
        )
        const studentHoursData = JSON.parse(
            chartData.getAttribute('data-student-hours')
        )

        const restaurants = productivityData.map((_, index) => ({
            name: chartData
                .getAttribute('data-restaurant-names')
                .split(',')
                [index].replace(/[\[\]"']+/g, ''),

            productivity: productivityData[index],
            cost_per_hour: costPerHourData[index],
            total_hours: totalHoursData[index],
            student_hours: studentHoursData[index],
        }))

        this.chart = Highcharts.chart('chart-data-dashboard-productivity', {
            chart: {
                type: 'line',
                zoomType: 'xy',
            },
            title: {
                text: null,
            },
            xAxis: {
                categories: periodsData,
                crosshair: true,
            },
            yAxis: {
                title: {
                    text: i18n.t('i18njs.Productivity'),
                },
                labels: {
                    format: '{value} €/h',
                },
            },
            tooltip: {
                useHTML: true,
                shared: true,
                formatter: function () {
                    const index = this.points[0].point.index
                    let tooltipHtml = `<span class="h5 mb-2">${periodsData[index]}</span><br/><table class="table table-striped table-bordered table-hover table-sm">`
                    tooltipHtml += `<thead><tr><th>Restaurant</th><th>${i18n.t('i18njs.Productivity')}</th><th>${i18n.t('i18njs.Cost per Hour')}</th><th>${i18n.t('i18njs.Total Hours')}</th><th>${i18n.t('i18njs.Student Hours')}</th></tr></thead><tbody>`

                    restaurants.forEach((restaurant, i) => {
                        tooltipHtml += `
                          <tr>
                              <td class="text-end fw-bold text-danger">${restaurant.name}</td>
                              <td class="text-end">${restaurant.productivity[index] || 0} €/h</td>
                              <td class="text-end">${restaurant.cost_per_hour[index] || 0} €/h</td>
                              <td class="text-end">${Math.round(restaurant.total_hours[index]) || 0} h</td>
                              <td class="text-end">${Math.round(restaurant.student_hours[index]) || 0}%</td>
                          </tr>`
                    })

                    tooltipHtml += '</tbody></table>'
                    return tooltipHtml
                },
            },
            series: productivityData.map((restaurantData, index) => ({
                name: restaurants[index].name,
                data: restaurantData,
                tooltip: {
                    valueSuffix: ' €/h',
                },
            })),
            credits: {
                enabled: false,
            },
        })
    }
}
