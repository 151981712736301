import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = [
        'input',
        'fixedBonusInput',
        'variableBonusInput',
        'growth',
        'turnoverByDay',
        'lossesFixedBonusInput',
        'lossesObjectiveInput',
        'percentStudentFixedBonusInput',
        'percentStudentObjectiveInput',
        'productivityFixedBonusInput',
        'productivityObjectiveInput',
        'staffCostFixedBonusInput',
        'staffCostObjectiveInput',
        'increaseButton',
        'decreaseButton'
    ]

    connect() {
        const inputs = this.element.querySelectorAll(
            'input[data-objective-target="input"]'
        )
        inputs.forEach((input) => {
            if (input.value && input.dataset.objectiveTarget === 'input') {
                this.calculateGrowth({ target: input })
            }
        })
    }

    async saveObjective(event) {
      event.preventDefault();
      const input = event.target;
      const tbody = input.closest('tbody');
      const yearAttribute = tbody.dataset.year;
      const actualYear = input.dataset.year;


      const value = parseFloat(input.value) || 0;
      if (value === 0) {
          return;
      }

      // Trouver tous les inputs associés au même mois dans le tbody
      const allInputs = {};
      const inputTypes = [
          'input',
          'fixedBonusInput',
          'variableBonusInput',
          'lossesFixedBonusInput',
          'lossesObjectiveInput',
          'percentStudentFixedBonusInput',
          'percentStudentObjectiveInput',
          'productivityFixedBonusInput',
          'productivityObjectiveInput',
          'staffCostFixedBonusInput',
          'staffCostObjectiveInput'
      ];

      // Chercher tous les inputs dans les lignes du tbody
      const rows = tbody.querySelectorAll(`tr[data-controller="objective"]`);
      rows.forEach(row => {
          const monthInputs = row.querySelectorAll(`input[data-month="${input.dataset.month}"]`);
          monthInputs.forEach(inp => {
              const targetType = inp.dataset.objectiveTarget;
              if (inputTypes.includes(targetType)) {
                  allInputs[targetType] = inp;
              }
          });
      });

      const data = {
          restaurant_objective: {
              restaurant_id: input.dataset.restaurantId,
              year: input.dataset.year,
              month: input.dataset.month,
              turnover_objective: allInputs.input?.value || null,
              turnover_fixed_bonus: allInputs.fixedBonusInput?.value || null,
              turnover_variable_bonus_percentage: allInputs.variableBonusInput?.value || null,
              losses_fixed_bonus: allInputs.lossesFixedBonusInput?.value || null,
              losses_objective: allInputs.lossesObjectiveInput?.value || null,
              percent_student_fixed_bonus: allInputs.percentStudentFixedBonusInput?.value || null,
              percent_student_objective: allInputs.percentStudentObjectiveInput?.value || null,
              productivity_fixed_bonus: allInputs.productivityFixedBonusInput?.value || null,
              productivity_objective: allInputs.productivityObjectiveInput?.value || null,
              staff_cost_fixed_bonus: allInputs.staffCostFixedBonusInput?.value || null,
              staff_cost_objective: allInputs.staffCostObjectiveInput?.value || null,
              day_filter: document.querySelector('input[name="day_filter"]:checked')?.value,
          },
      }

      // Vérifier si un objectif existe déjà pour ce mois
      const existingValue = input.dataset.existingValue === 'true'

      let baseUrl = '/restaurant_objectives'
      if (document.body.dataset.locale) {
          baseUrl = `/${document.body.dataset.locale}${baseUrl}`
      }

      // Si c'est un update, ajouter l'ID dans l'URL
      if (existingValue) {
          baseUrl = `${baseUrl}/${data.restaurant_objective.restaurant_id}`
      }

      try {
          const response = await fetch(baseUrl, {
              method: existingValue ? 'PATCH' : 'POST',
              credentials: 'same-origin',
              headers: {
                  'Content-Type': 'application/json',
                  Accept: 'application/json',
                  'X-Requested-With': 'XMLHttpRequest',
                  'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
              },
              body: JSON.stringify(data),
          })

          if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`)
          }

          const result = await response.json()

          if (result.success) {
              // input.classList.add('is-valid')
              // setTimeout(() => input.classList.remove('is-valid'), 2000)
              if (!existingValue) {
                  input.dataset.existingValue = 'true'
                  input.value = value;
              }
              const bonusCalculationController =
                  this.application.getControllerForElementAndIdentifier(
                      tbody,
                      'bonus-calculation'
                  )
              if (bonusCalculationController) {
                  bonusCalculationController.calculateAllBonus()
              }
          } else {
              // input.classList.add('is-invalid')
              // setTimeout(() => input.classList.remove('is-invalid'), 2000)
              console.error('Erreur lors de la sauvegarde:', result.errors)
          }
      } catch (error) {
          console.error('Erreur:', error)
          // input.classList.add('is-invalid')
          // setTimeout(() => input.classList.remove('is-invalid'), 2000)
      }
  }

    calculateGrowth(event) {
        const currentInput = event.target
        const inputIndex = this.inputTargets.indexOf(currentInput)
        const growthDiv = this.growthTargets[inputIndex]
        const turnoverByDayDiv = this.turnoverByDayTargets[inputIndex]
        const tbody = currentInput.closest('tbody')
        const currentValue = parseFloat(currentInput.dataset.currentValue) || 0
        const objectiveValue = parseFloat(currentInput.value) || 0
        const month = parseInt(currentInput.dataset.month)

        // Trouver la ligne des jours comptés
        const daysRow = tbody.querySelector(
            '[data-bonus-calculation-target="daysRow"]'
        )
        if (!daysRow) return

        // Trouver la cellule du mois correspondant
        const daysCells = Array.from(daysRow.querySelectorAll('td'))
        const daysCell = daysCells[month]
        if (!daysCell) return

        // Extraire les jours réalisés et théoriques
        const daysText = daysCell.textContent.trim()
        const isNextYear = daysCell.dataset.nextYear === 'true'

        let realizedDays = 0
        let theoreticalDays = 0

        if (isNextYear) {
            // Si c'est `next_year`, utilisez uniquement les jours théoriques
            theoreticalDays = parseInt(daysText) || 0
        } else {
            // Sinon, séparez en "réalisés/théoriques"
            ;[realizedDays, theoreticalDays] = daysText
                .split('/')
                .map((d) => parseInt(d) || 0)
        }

        if (objectiveValue > 0) {
            // Pour l'affichage du CA/jour, toujours utiliser les jours théoriques
            if (theoreticalDays > 0) {
                const turnoverByDayValue = (
                    objectiveValue / theoreticalDays
                ).toFixed(0)
                turnoverByDayDiv.innerHTML = `<div class="text-muted">${turnoverByDayValue.replace('.', ',')} €/j</div>`
            }

            // Calcul et affichage de la croissance
            if (currentValue > 0) {
                const growth = (
                    ((objectiveValue - currentValue) / currentValue) *
                    100
                ).toFixed(1)
                const isPositive = parseFloat(growth) > 0
                growthDiv.innerHTML = `<div class="${isPositive ? 'text-success' : 'text-danger'}">${growth.replace('.', ',')}%</div>`
            } else {
                growthDiv.innerHTML = ''
            }
        } else {
            growthDiv.innerHTML = ''
            turnoverByDayDiv.innerHTML = ''
        }

        // Notifier le bonus-calculation du changement
        const bonusCalculationController =
            this.application.getControllerForElementAndIdentifier(
                tbody,
                'bonus-calculation'
            )
        if (bonusCalculationController) {
            bonusCalculationController.calculateAllBonus()
        }
    }

    increaseByPercent(event) {
      const input = event.target
          .closest('td')
          .querySelector('[data-objective-target="input"]')
      const currentBaseValue = parseFloat(input.dataset.currentValue) || 0

      // Calculer le pourcentage actuel
      const currentGrowthRate = this.getCurrentGrowthRate(input)

      // Trouver le prochain pourcentage entier
      const nextPercent = Math.ceil(currentGrowthRate + 0.1)  // +0.1 pour éviter les problèmes d'arrondi

      // Calculer la nouvelle valeur
      const newValue = Math.round(currentBaseValue * (1 + nextPercent / 100))

      input.value = newValue
      this.calculateGrowth({ target: input })
      this.saveObjective({ preventDefault: () => {}, target: input })
  }

  decreaseByPercent(event) {
      const input = event.target
          .closest('td')
          .querySelector('[data-objective-target="input"]')
      const currentBaseValue = parseFloat(input.dataset.currentValue) || 0

      // Calculer le pourcentage actuel
      const currentGrowthRate = this.getCurrentGrowthRate(input)

      // Trouver le pourcentage entier précédent
      const prevPercent = Math.floor(currentGrowthRate - 0.1)  // -0.1 pour éviter les problèmes d'arrondi

      // Calculer la nouvelle valeur
      const newValue = Math.round(currentBaseValue * (1 + prevPercent / 100))

      input.value = newValue
      this.calculateGrowth({ target: input })
      this.saveObjective({ preventDefault: () => {}, target: input })
  }

  getCurrentGrowthRate(input) {
      const currentBaseValue = parseFloat(input.dataset.currentValue) || 0
      const currentValue = parseFloat(input.value) || 0

      if (currentBaseValue === 0) return 0
      const growthRate = ((currentValue - currentBaseValue) / currentBaseValue) * 100
      return growthRate
  }
}
