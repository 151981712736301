import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr"
import { French } from "flatpickr/dist/l10n/fr.js"

export default class extends Controller {
  connect() {
    this.fp = flatpickr(this.element, {
      dateFormat: "d/m/Y",
      locale: French,
      allowInput: true
    })
  }

  disconnect() {
    this.fp.destroy()
  }
}
