// app/javascript/controllers/ticket_timepickr_controller.js
import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr"
import { French } from "flatpickr/dist/l10n/fr.js"

export default class extends Controller {
  connect() {
    this.fp = flatpickr(this.element, {
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      time_24hr: true,
      locale: French,
      allowInput: true,
      minuteIncrement: 5,
      onOpen: (selectedDates, dateStr, instance) => {
        // Ajouter le bouton quand le picker s'ouvre
        const wrapper = instance.calendarContainer;
        if (!wrapper.querySelector('.time-confirm-btn')) {
          const button = document.createElement('button');
          button.className = 'btn btn-sm btn-primary my-2 time-confirm-btn';
          button.innerHTML = 'Valider';
          button.onclick = () => instance.close();
          wrapper.appendChild(button);
        }
      }
    })
  }

  disconnect() {
    this.fp.destroy()
  }
}
