import { Controller } from '@hotwired/stimulus'
import { I18n } from 'i18n-js'
import translations from '../translations.json'

const i18n = new I18n(translations)
const userLocale = document
    .querySelector("meta[name='user-locale']")
    .getAttribute('content')
i18n.locale = userLocale

export default class extends Controller {
    static targets = ['time']

    connect() {
        this.setDefaultTime()
        if (typeof Highcharts === 'undefined') {
            document.addEventListener('DOMContentLoaded', () => {
                setTimeout(() => this.loadChart(), 100)
            })
        } else {
            this.loadChart()
        }
    }

    setDefaultTime() {
        const selectElement = this.element.querySelector('select')
        if (selectElement && selectElement.options.length > 0) {
            selectElement.options[0].selected = true
        }
    }

    updateChart() {
        const selectedPeriod = this.timeTarget.value

        if (!this.chart) {
            console.error('Chart is not initialized yet.')
            return
        }

        this.chart.showLoading()

        let url = `/dashboard/call_chart_losses_service?period=${selectedPeriod}&format=json`
        if (document.body.dataset.locale) {
            url = `/${document.body.dataset.locale}${url}`
        }

        fetch(url, {
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')
                    .content,
                'X-User-Anonymize': document.querySelector('meta[name=user-anonymize]')?.content || 'false'
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok')
                }
                return response.json()
            })
            .then((data) => {
                if (data && data.length > 0) {
                    this.chart.update(
                        {
                            series: data.map((lossesData) => ({
                                name: lossesData.restaurant_name,
                                data: Object.entries(
                                    lossesData.daily_losses
                                ).map(([date, lossData]) => ({
                                    x: new Date(date).getTime(),
                                    y: parseFloat(
                                        lossData.daily_losses_total_percent
                                    ),
                                    turnover: parseFloat(
                                        lossData.daily_turnover
                                    ),
                                    totalLoss: parseFloat(
                                        lossData.daily_losses_total
                                    ),
                                    totalLossPercent: parseFloat(
                                        lossData.daily_losses_total_percent
                                    ),
                                    items: lossData.items, // Pour l'infobulle
                                })),
                            })),
                        },
                        false
                    ) // Le second paramètre 'false' empêche les redraws inutiles

                    this.chart.redraw() // Redessine explicitement le graphique
                } else {
                    console.warn('No data received for the selected period.')
                    this.chart.series.forEach((series) => series.setData([])) // Vide le graphique si aucune donnée n'est reçue
                }

                this.chart.hideLoading()
            })
            .catch((error) => {
                console.error('Error updating chart:', error)
            })
    }

    loadChart() {
        if (typeof Highcharts === 'undefined') {
            console.error('Highcharts not loaded')
            return
        }
        const chartDataElement = this.element.querySelector(
            '#chart-data-dashboard-losses'
        )
        const lossesData = JSON.parse(
            chartDataElement.getAttribute('data-losses')
        )

        this.chart = Highcharts.chart('chart-data-dashboard-losses', {
            chart: {
                type: 'spline',
            },
            title: {
                text: null,
            },
            xAxis: {
                type: 'datetime',
                title: {
                    text: `${i18n.t('i18njs.Date')}`,
                },
            },
            yAxis: {
                title: {
                    text: i18n.t('i18njs.Percentage Loss'),
                },
                labels: {
                    format: '{value:.1f}%', // Format des labels Y pour les pourcentages
                },
            },
            tooltip: {
                useHTML: true,
                shared: false,
                formatter: function () {
                    let turnoverFormatted =
                        Highcharts.numberFormat(
                            this.point.turnover,
                            0,
                            ',',
                            ' '
                        ) + '€'
                    let totalLossFormatted =
                        Highcharts.numberFormat(
                            this.point.totalLoss,
                            0,
                            ',',
                            ' '
                        ) + '€'
                    let totalLossPercentFormatted =
                        Highcharts.numberFormat(
                            this.point.totalLossPercent,
                            1,
                            ',',
                            '.'
                        ) + '%'
                    let tooltipHtml = `<b>${this.series.name.toUpperCase()} - ${Highcharts.dateFormat('%e %b %Y', this.x)}</b><br/><b>${i18n.t('i18njs.Turnover')} : ${turnoverFormatted}</b><br/><b>${i18n.t('i18njs.Total Losses')} : ${totalLossFormatted}</b><br/><b>${i18n.t('i18njs.Total Losses')} : ${totalLossPercentFormatted}</b><br/><br/><table class="table table-striped table-bordered table-hover table-sm">`
                    tooltipHtml += `<thead><tr><th>${i18n.t('i18njs.Name')}</th><th>${i18n.t('i18njs.Quantity')}</th><th>${i18n.t('i18njs.Cost Lost')}</th><th>${i18n.t('i18njs.Loss/Sales')}</th></tr></thead><tbody>`

                    let sortedItems = this.point.items.sort(
                        (a, b) => b.quantity - a.quantity
                    )
                    let topItems = sortedItems.slice(0, 6)
                    let otherItems = sortedItems.slice(6)

                    topItems.forEach((item) => {
                        const costLostFormatted = Highcharts.numberFormat(
                            parseFloat(item.cost_lost), // Convertir en nombre
                            0,
                            ',',
                            '.'
                        ) // Affichage sans centimes
                        const lossPercentageFormatted = Highcharts.numberFormat(
                            parseFloat(item.loss_percentage), // Convertir en nombre
                            1,
                            ',',
                            '.'
                        )
                        tooltipHtml += `<tr><td>${item.name}</td><td>${item.quantity}</td><td>${costLostFormatted}€</td><td>${lossPercentageFormatted}%</td></tr>`
                    })

                    if (otherItems.length > 0) {
                        let otherTotal = otherItems.reduce(
                            (sum, item) => sum + item.quantity,
                            0
                        )
                        tooltipHtml += `<tr><td>${i18n.t('i18njs.Others')}</td><td>${otherTotal}</td><td>-</td><td>-</td></tr>`
                    }

                    tooltipHtml += `</tbody></table>`
                    return tooltipHtml
                },
            },
            plotOptions: {
                series: {
                    stickyTracking: false,
                    dataLabels: {
                        enabled: true,
                        formatter: function () {
                            return `${parseFloat(this.point.y).toFixed(2)}%` // Convertir en nombre
                        },
                    },
                },
            },

            series: lossesData.map((lossesData) => ({
                name: lossesData.restaurant_name,
                data: Object.entries(lossesData.daily_losses).map(
                    ([date, lossData]) => ({
                        x: new Date(date).getTime(),
                        y: parseFloat(lossData.daily_losses_total_percent),
                        turnover: parseFloat(lossData.daily_turnover),
                        totalLoss: parseFloat(lossData.daily_losses_total),
                        totalLossPercent: parseFloat(
                            lossData.daily_losses_total_percent
                        ),
                        items: lossData.items,
                    })
                ),
            })),
            credits: {
                enabled: false,
            },
        })
    }
}
