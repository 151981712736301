import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["list", "input", "dateInput", "timeInput"]

  updateDateTime(event) {
    const date = this.dateInputTarget.value
    const time = this.timeInputTarget.value
    const formData = new FormData()
    formData.append('support_ticket[intervention_date_formatted]', `${date} ${time}`)
    this.sendRequest(
      event.target.closest('form').action,
      'PATCH',
      formData
    )
  }

  refuseIntervention(event) {
    const formData = new FormData()
    formData.append('support_ticket[status]', 'refused')
    this.sendRequest(
      event.target.closest('form').action,
      'PATCH',
      formData
    )
  }
  
  submit(event) {
    event.preventDefault()
    const form = event.target
    this.sendRequest(form.action, form.method, new FormData(form))
  }

  sendRequest(url, method, formData) {
    fetch(url, {
      method: method,
      body: formData,
      headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content
      }
    })
    .then(response => response.json())
    .then(data => {
      if (data.message_html) {
        this.listTarget.insertAdjacentHTML('afterbegin', data.message_html)
        if (this.hasInputTarget) {
          this.inputTarget.value = ''
        }
      }
    })
  }
}
