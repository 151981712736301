import { Controller } from '@hotwired/stimulus'

import { I18n } from 'i18n-js'
import translations from '../translations.json'

const i18n = new I18n(translations)

const userLocale = document
    .querySelector("meta[name='user-locale']")
    .getAttribute('content')
i18n.locale = userLocale

export default class extends Controller {
    connect() {
        if (typeof Highcharts === 'undefined') {
            document.addEventListener('DOMContentLoaded', () => {
                setTimeout(() => this.loadChart(), 100)
            })
        } else {
            this.loadChart()
        }
    }
    
    loadChart() {
        const chartData = this.element.querySelector('#chart-data')
        const categories = JSON.parse(chartData.getAttribute('data-categories'))
        const priceData = JSON.parse(chartData.getAttribute('data-series'))
        const piecesData = JSON.parse(
            chartData.getAttribute('data-pieces-series')
        )

        Highcharts.chart('chart-data', {
            chart: { type: 'column' },
            title: { text: null },
            xAxis: {
                categories: categories,
                crosshair: true,
            },
            yAxis: {
                title: { text: i18n.t('i18njs.Total Purchase Amount (€)') },
            },
            tooltip: {
                shared: true,
                useHTML: true,
                formatter: function () {
                    let index = this.points[0].point.index
                    return `<b>${categories[index]}</b><br/>
                          Total: ${(this.points[0].y / 100).toLocaleString('fr-FR', { minimumFractionDigits: 0 })} €<br/>
                          ${i18n.t('i18njs.Quantity')}: ${piecesData[index]} ${i18n.t('i18njs.pcs')}`
                },
            },
            series: [
                {
                    name: i18n.t('i18njs.Purchase Amount'),
                    data: priceData,
                    color: 'brown',
                    dataLabels: {
                        enabled: true,
                        formatter: function () {
                            let index = this.point.index
                            return `${piecesData[index]} ${i18n.t('i18njs.pcs')}`
                        },
                    },
                },
            ],
            credits: { enabled: false },
        })
    }
}
