import { Controller } from '@hotwired/stimulus'
import { I18n } from 'i18n-js'
import translations from '../translations.json'

const i18n = new I18n(translations)
const userLocale = document
    .querySelector("meta[name='user-locale']")
    .getAttribute('content')
i18n.locale = userLocale

export default class extends Controller {
    static targets = ['status', 'content', "progress"]

    static values = {
        maxAttempts: { type: Number, default: 360 }, // 6 minutes
        currentAttempt: { type: Number, default: 0 },
        minDisplayTime: { type: Number, default: 2000 }
    }

    connect() {
      const status = this.contentTarget.dataset.status

      if (status === 'processing') {
          this.startTime = Date.now()
          this.showLoadingModal()
          this.startPolling()
        }
    }

    showLoadingModal() {
      this.startTime = Date.now()
      const customMessage = this.element.dataset.loadingMessage ||
        `${i18n.t('i18njs.Please wait while we process your request')}...`

      if (!this.modal) {
        this.modal = new bootstrap.Modal(document.getElementById('loadingModalStock'))
      }

      const messageEl = document.querySelector('#loadingModalStock .modal-body p')
      if (messageEl) messageEl.textContent = customMessage

      if (this.hasProgressTarget) {
        this.progressTarget.style.width = '0%'
      }

      this.modal.show()
    }

    hideLoadingModal() {
      const currentTime = Date.now()
      const elapsedTime = currentTime - this.startTime

      if (elapsedTime < this.minDisplayTimeValue) {
        setTimeout(() => {
          this.modal?.hide()
        }, this.minDisplayTimeValue - elapsedTime)
      } else {
        this.modal?.hide()
      }
    }

    updateProgress(percent) {
      if (this.hasProgressTarget) {
        this.progressTarget.style.width = `${percent}%`
        this.progressTarget.setAttribute('aria-valuenow', percent)
    }
    }


    disconnect() {
      this.stopPolling()
      this.modal?.hide()
    }

    startPolling() {
        if (this.pollInterval) this.stopPolling()
        this.pollInterval = setInterval(() => {
            this.checkStatus()
        }, 5000)
    }

    stopPolling() {
        if (this.pollInterval) {
            clearInterval(this.pollInterval)
            this.pollInterval = null
        }
    }

    buildUrl() {
        const currentUrl = new URL(window.location.href)
        const url = new URL(
            `${window.location.pathname}.json`,
            window.location.origin
        )
        currentUrl.searchParams.forEach((value, key) => {
            url.searchParams.append(key, value)
        })
        return url
    }

    getHeaders() {
        return {
            Accept: 'application/json',
            'X-CSRF-Token':
                document.querySelector('[name="csrf-token"]')?.content || '',
            'X-Requested-With': 'XMLHttpRequest',
        }
    }

    async checkStatus() {
        this.currentAttemptValue++

        if (this.currentAttemptValue >= this.maxAttemptsValue) {
            this.stopPolling()
            this.showError(
                'Le calcul a pris trop de temps. Veuillez réessayer.'
            )
            return
        }

        try {
            const response = await fetch(this.buildUrl(), {
                headers: this.getHeaders(),
                credentials: 'same-origin',
            })

            if (!response.ok)
                throw new Error(`HTTP error! status: ${response.status}`)

            const data = await response.json()
            this.estimatedDuration = data.estimated_duration || 10000 // fallback 10s

            const estimatedDurationElement = document.getElementById('estimated-duration-value');
            if (estimatedDurationElement) {
                const seconds = Math.ceil(this.estimatedDuration / 1000); // Convertir en secondes
                estimatedDurationElement.textContent = `${seconds} ${i18n.t('i18njs.seconds')}`;
            }

            if (data.status === 'processing') {
                const elapsedTime = Date.now() - this.startTime
                let progress = Math.min((elapsedTime / this.estimatedDuration) * 100, 95)
                this.updateProgress(progress)
            }

            if (data.status === 'completed') {
                this.updateProgress(100)
                this.hideLoadingModal()
                this.stopPolling()
                if (data.data) {
                    this.contentTarget.innerHTML = data.data
                }
                if (data.totals) {
                    this.updateTotals(data.totals)
                }
                if (data.selected_params) {
                    this.restoreParameters(data.selected_params)
                }
            } else if (data.status === 'error') {
                this.stopPolling()
                this.showError(data.message || 'Une erreur est survenue...')
            } else if (data.status === 'processing') {
                // On continue, on ne fait rien
            } else if (data.status === 'no_params') {
                this.stopPolling()
            } else {
                this.stopPolling()
                this.showError('Statut inconnu: ' + data.status)
            }
        } catch (error) {
            console.error('Error checking status:', error)
            this.stopPolling()
            this.hideLoadingModal()
            this.showError('Erreur de communication avec le serveur')
        }
    }

    updateTotals(params) {
      const deltaTotalElement = document.getElementById('total_delta')
      const deltaEuroElement = document.getElementById('total_delta_euro')
      const revenueDeltaElement = document.getElementById('total_revenue_delta')

      if (deltaTotalElement && params.total_delta != null) {
          const totalDelta = parseFloat(params.total_delta)
          if (!isNaN(totalDelta)) {
              deltaTotalElement.textContent = Math.round(totalDelta).toLocaleString('fr-FR')
              deltaTotalElement.classList.remove('text-success', 'text-danger')
              deltaTotalElement.classList.add(totalDelta > 0 ? 'text-success' : 'text-danger')
          }
      }

      if (deltaEuroElement && params.total_delta_euro != null) {
          const totalDeltaEuro = parseFloat(params.total_delta_euro)
          if (!isNaN(totalDeltaEuro)) {
              deltaEuroElement.textContent = `${Math.round(totalDeltaEuro / 100).toLocaleString('fr-FR')} €`
              deltaEuroElement.classList.remove('text-success', 'text-danger')
              deltaEuroElement.classList.add(totalDeltaEuro > 0 ? 'text-success' : 'text-danger')
          }
      }

      if (revenueDeltaElement && params.total_revenue_delta != null) {
          const totalRevenueDelta = parseFloat(params.total_revenue_delta)
          if (!isNaN(totalRevenueDelta)) {
              revenueDeltaElement.textContent = `${Math.round(totalRevenueDelta / 100).toLocaleString('fr-FR')} €`
              revenueDeltaElement.classList.remove('text-success', 'text-danger')
              revenueDeltaElement.classList.add(totalRevenueDelta > 0 ? 'text-success' : 'text-danger')
          }
      }
  }

    restoreParameters(params) {
      console.log(params)
      // Restaurer les dates et heures
      if (params.start_date) {
        const startDateInput = document.getElementById('floatingStartDate')
        if (startDateInput) {
          startDateInput.value = params.start_date
        }
      }

      if (params.end_date) {
        const endDateInput = document.getElementById('floatingEndDate')
        if (endDateInput) {
          endDateInput.value = params.end_date
        }
      }

      if (params.start_time) {
        const startTimeInput = document.getElementById('floatingStartTime')
        if (startTimeInput) startTimeInput.value = params.start_time
      }

      if (params.end_time) {
        const endTimeInput = document.getElementById('floatingEndTime')
        if (endTimeInput) endTimeInput.value = params.end_time
      }

      // Restaurer les catégories
      if (params.secondary_categories) {
        params.secondary_categories.forEach(catId => {
          const checkbox = document.querySelector(`input[name="secondary_categories[]"][value="${catId}"]`)
          if (checkbox) checkbox.checked = true
        })
      }

      if (params.primary_categories) {
        const primaryCats = Array.isArray(params.primary_categories) ?
          params.primary_categories :
          [params.primary_categories]

        primaryCats.forEach(catId => {
          const checkbox = document.querySelector(`input[name="primary_categories[]"][value="${catId}"]`)
          if (checkbox) checkbox.checked = true
        })
      }

      const formatDate = (dateStr) => {
        if (!dateStr) return '';
        const date = new Date(dateStr);
        return date.toLocaleDateString('fr-FR');  // Retournera le format dd/mm/yyyy
      };

      const reportTitle = document.getElementById('report-title')
      if (reportTitle && params.start_date && params.end_date) {
          reportTitle.innerHTML = `
              <i class="fa-solid fa-shop fa-xl text-primary me-2"></i>
              ${i18n.t('Stock report')} ${i18n.t('from')}
              ${formatDate(params.start_date)}
              ${i18n.t('to')}
              ${formatDate(params.end_date)}
          `;
      }
    }

    showError(message) {
        if (this.hasStatusTarget) {
            this.statusTarget.textContent = message
            this.statusTarget.classList.add('error')
        } else {
            console.error('Status target not found for message:', message)
        }
    }

}
