import { Controller } from "@hotwired/stimulus"
import { I18n } from 'i18n-js'
import translations from '../translations.json'

const i18n = new I18n(translations)
const userLocale = document
    .querySelector("meta[name='user-locale']")
    .getAttribute('content')
i18n.locale = userLocale

export default class extends Controller {
  static targets = ["restaurant", "provider", "submitButton", "warningMessage"]

  connect() {
    this.providerTarget.disabled = true
  }

  async updateProviders() {
    const restaurantId = this.restaurantTarget.value
    if (!restaurantId) {
      this.providerTarget.disabled = true
      this.providerTarget.innerHTML = `<option value="">-- ${i18n.t('i18njs.Select Provider')} --</option>`
      this.resetSubmitButton()
      return
    }

    let url = `/restaurants/${restaurantId}/service_providers`
    if (document.body.dataset.locale) {
        url = `/${document.body.dataset.locale}${url}`
    }

    const response = await fetch(url)
    const providers = await response.json()

    this.providerTarget.innerHTML = `<option value="">-- ${i18n.t('i18njs.Select Provider')} --</option>`
    providers.forEach(provider => {
      this.providerTarget.innerHTML += `<option value="${provider.id}">${provider.name}</option>`
    })
    this.providerTarget.disabled = false

    // Reset l'état du bouton lorsqu'un nouveau provider est sélectionné
    this.resetSubmitButton()
  }

  // Nouvelle méthode pour gérer le changement de provider
  async handleProviderChange(event) {
    const providerId = event.target.value
    if (!providerId) {
      this.resetSubmitButton()
      return
    }

    let url = `/service_providers/${providerId}/check_users`
    if (document.body.dataset.locale) {
        url = `/${document.body.dataset.locale}${url}`
    }

    try {
      const response = await fetch(url)
      const data = await response.json()

      if (!data.has_users) {
        this.submitButtonTarget.classList.add('disabled')
        this.warningMessageTarget.classList.remove('d-none')
      } else {
        this.submitButtonTarget.classList.remove('disabled')
        this.warningMessageTarget.classList.add('d-none')
      }
    } catch (error) {
      console.error('Error checking provider users:', error)
    }
  }

  resetSubmitButton() {
    this.submitButtonTarget.classList.remove('disabled')
    this.warningMessageTarget.classList.add('d-none')
  }
}
