import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="unit-price"
export default class extends Controller {
    static targets = [
        'price',
        'quantity',
        'unitPrice',
        'unitWeight',
        'secondaryCategory',
        'primaryCategory',
        'unitMeasure'
    ]

    connect() {
      if (this.primaryCategoryTarget.value) {
          this.secondaryCategoryTarget.disabled = false
          if (!this.secondaryCategoryTarget.value) {
              this.primaryCategoryChange()
          }
      } else {
          this.secondaryCategoryTarget.disabled = true
      }
      const currentUnit = this.unitMeasureTarget.value
      this.toggleUnitWeight(currentUnit)
  }

  toggleUnitWeight(selectedUnit) {
    if (selectedUnit === 'PC') {
      this.unitWeightTarget.removeAttribute('disabled')
    } else {
      this.unitWeightTarget.setAttribute('disabled', 'true')
      this.unitWeightTarget.value = ''
    }
  }

    calculateUnitPrice() {
        const priceValue = this.priceTarget.value.replace(',', '.')
        const quantityValue = this.quantityTarget.value.replace(',', '.')
        this.priceTarget.value = priceValue
        this.quantityTarget.value = quantityValue
        const price = parseFloat(priceValue)
        const quantity = parseFloat(quantityValue)
        console.log('Parsed price:', price)
        console.log('Parsed quantity:', quantity)
        if (!isNaN(price) && !isNaN(quantity) && quantity !== 0) {
            const unitPrice = price / quantity
            const formattedUnitPrice = unitPrice.toFixed(2)
            console.log('Unit price:', formattedUnitPrice)
            console.log('formated', formattedUnitPrice)
            console.log('Target element:', this.unitPriceTarget)
            console.log('Target element val:', this.unitPriceTarget.value)
            this.unitPriceTarget.value = formattedUnitPrice
        } else {
            this.unitPriceTarget.value = ''
        }
    }

    enableUnitWeightInput(event) {
      this.toggleUnitWeight(event.currentTarget.value)
    }

    async primaryCategoryChange() {
      const primaryCategoryId = this.primaryCategoryTarget.value
      this.secondaryCategoryTarget.disabled = primaryCategoryId ? false : true
      let url = `/ingredient_secondary_categories/filter_by_primary?primary_category_id=${primaryCategoryId}`
      if (document.body.dataset.locale) {
          url = `/${document.body.dataset.locale}${url}`
      }
      try {
          const response = await fetch(url)
          const data = await response.json()

          const { secondary_categories } = data
          this.secondaryCategoryTarget.innerHTML = ''
          const blankOption = document.createElement('option')
          blankOption.value = ''
          blankOption.text = ''
          this.secondaryCategoryTarget.appendChild(blankOption)
          secondary_categories.forEach((category, index) => {
              const option = document.createElement('option')
              option.value = category.id
              option.text = category.name
              this.secondaryCategoryTarget.appendChild(option)
          })
      } catch (error) {
          console.log('Fetch error', error)
      }
  }
}
