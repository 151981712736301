import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["restaurant", "month", "year"]

  filterChanged(event) {
    const restaurantFilter = this.restaurantTarget.value
    const monthFilter = this.monthTarget.value
    const yearFilter = this.yearTarget.value
    const filterType = event.target.dataset.filterType

    // 1. Gestion spéciale du tableau Stock Variations
    const stockRows = document.querySelectorAll('tr[data-restaurant-id]')
    stockRows.forEach(row => {
      let showRow = true
      const rowDate = row.querySelector('td:first-child')?.textContent
      const isTotal = row.dataset.restaurantId === ""
      const hasVariation = row.hasAttribute('data-stock-variation')

      // Si c'est une ligne du tableau stock variation
      if (isTotal || hasVariation) {
        // Si c'est un filtre mois, on garde l'état actuel
        if (filterType === 'month') {
          return
        }

        // Pour filtre restaurant
        if (restaurantFilter) {
          // Si restaurant sélectionné : montrer seulement les lignes de ce restaurant
          showRow = row.dataset.restaurantId === restaurantFilter
        } else {
          // Si pas de restaurant sélectionné : montrer seulement les totaux
          showRow = isTotal
        }

        // Pour filtre année
        if (yearFilter && showRow) {
          const rowYear = parseInt(rowDate.split('/')[2] || rowDate.split('/')[1])
          showRow = showRow && (rowYear === parseInt(yearFilter))
        }
      } else {
        // Pour tous les autres tableaux
        if (restaurantFilter && row.dataset.restaurantId !== restaurantFilter) {
          showRow = false
        }

        if (rowDate) {
          const [day, month, year] = rowDate.split('-').map(n => parseInt(n, 10))

          if (monthFilter && parseInt(month) !== parseInt(monthFilter)) {
            showRow = false
          }

          if (yearFilter && (2000 + parseInt(year)) !== parseInt(yearFilter)) {
            showRow = false
          }
        }
      }

      row.style.display = showRow ? '' : 'none'
    })



    // 3. Mise à jour des graphique
    let filteredOrders = []
    let filteredSales = []
    let filteredInternal = []
    let filteredAdjustments = []

    const chartController = this.application.getControllerForElementAndIdentifier(
      document.querySelector('[data-controller="purchase-history-chart"]'),
      'purchase-history-chart'
    )

    if (chartController) {
      filteredOrders = chartController.orderData
      filteredSales = chartController.salesRawData
      filteredInternal = chartController.internalRawData
      filteredAdjustments = chartController.adjustmentsData   // Notez 'Adjustments' ici

      if (restaurantFilter) {
        const restaurantId = parseInt(restaurantFilter)
        filteredOrders = filteredOrders.filter(o => o.restaurant_id === restaurantId)
        filteredSales = filteredSales.filter(s => s.restaurant_id === restaurantId)
        filteredInternal = filteredInternal.filter(i => i.restaurant_id === restaurantId)
        filteredAdjustments = filteredAdjustments.filter(a => a.restaurant_id === restaurantId)
      }

      if (yearFilter) {
        const year = parseInt(yearFilter)
        filteredOrders = filteredOrders.filter(o => new Date(o.created_at).getFullYear() === year)
        filteredSales = filteredSales.filter(s => new Date(s.created_at).getFullYear() === year)
        filteredInternal = filteredInternal.filter(i => new Date(i.created_at).getFullYear() === year)
        filteredAdjustments = filteredAdjustments.filter(a => new Date(a.date).getFullYear() === year)
      }

      if (monthFilter) {
        const month = parseInt(monthFilter)
        filteredOrders = filteredOrders.filter(o => new Date(o.created_at).getMonth() + 1 === month)
        filteredSales = filteredSales.filter(s => new Date(s.created_at).getMonth() + 1 === month)
        filteredInternal = filteredInternal.filter(i => new Date(i.created_at).getMonth() + 1 === month)
        // Ne pas filtrer les ajustements par mois
      }

      const monthlyPurchases = chartController.calculateMonthlySums(filteredOrders, 'purchases')
      const monthlySales = chartController.calculateMonthlySums(filteredSales, 'sales')
      const monthlyInternal = chartController.calculateMonthlySums(filteredInternal, 'internal')
      const monthlyAdjustments = chartController.calculateMonthlySums(filteredAdjustments, 'adjustments')  // Notez 'Adjustments' ici

      chartController.renderChart(monthlyPurchases, monthlySales, monthlyInternal, monthlyAdjustments)
    }

    const chartProfitController = this.application.getControllerForElementAndIdentifier(
      document.querySelector('[data-controller="profit-margins-chart"]'),
      'profit-margins-chart'
    )

    if (chartProfitController) {
      chartProfitController.orderData = filteredOrders
      chartProfitController.salesData = filteredSales
      chartProfitController.internalData = filteredInternal
      chartProfitController.adjustmentsData = filteredAdjustments   // Notez 'Adjustments' ici

      chartProfitController.initChart()
    }
  }
}
