import { Controller } from '@hotwired/stimulus'
import { I18n } from 'i18n-js'
import translations from '../translations.json'

const i18n = new I18n(translations)
const userLocale = document
    .querySelector("meta[name='user-locale']")
    .getAttribute('content')
i18n.locale = userLocale

export default class extends Controller {
    static targets = ['chart']

    connect() {
        this.orderData = JSON.parse(this.element.dataset.orders)
        this.salesRawData = JSON.parse(this.element.dataset.sales)
        this.internalRawData = JSON.parse(this.element.dataset.internal)
        this.adjustmentsData = JSON.parse(this.element.dataset.adjustments)
        this.restaurants = JSON.parse(this.element.dataset.restaurants)

        if (typeof Highcharts === 'undefined') {
            document.addEventListener('DOMContentLoaded', () => {
                setTimeout(() => this.initChart(), 100)
            })
        } else {
            this.initChart()
        }
    }

    initChart() {
        const monthlyPurchases = this.calculateMonthlySums(
            this.orderData,
            'purchases'
        )
        const monthlySales = this.calculateMonthlySums(
            this.salesRawData,
            'sales'
        )
        const monthlyInternal = this.calculateMonthlySums(
            this.internalRawData,
            'internal'
        )
        const monthlyAdjustments = this.calculateMonthlySums(
            this.adjustmentsData,
            'adjustments'
        )
        this.renderChart(
            monthlyPurchases,
            monthlySales,
            monthlyInternal,
            monthlyAdjustments
        )
    }

    initializeYearSelect() {
        const select = document.createElement('select')
        select.className = 'form-select w-auto mb-3'
        select.innerHTML = `
          <option value="">${i18n.t('i18njs.all_years')}</option>
          ${this.years.map((year) => `<option value="${year}">${year}</option>`).join('')}
      `
        select.addEventListener('change', (e) => this.handleYearChange(e))
        this.element.insertBefore(select, this.chartTarget)
        this.yearSelect = select
    }

    handleYearChange(e) {
        const year = e.target.value
        const filteredData = year
            ? this.orderData.filter(
                  (order) =>
                      new Date(order.created_at).getFullYear() ===
                      parseInt(year)
              )
            : this.orderData

        this.renderChart(this.calculateMonthlySums(filteredData))
    }

    calculateMonthlySums(orders, type = 'purchases') {
        const monthlyData = {}

        if (type === 'adjustments') {
            orders.forEach((adj) => {
                const date = new Date(adj.date)
                if (isNaN(date)) return

                const monthKey = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`
                if (!monthlyData[monthKey]) {
                    monthlyData[monthKey] = { total: 0, restaurants: {} }
                }

                if (!monthlyData[monthKey].restaurants[adj.restaurant_name]) {
                    monthlyData[monthKey].restaurants[adj.restaurant_name] = {
                        qty: 0,
                        amount: 0,
                        adjustments: 0,
                        adjustmentsQty: 0,
                    }
                }

                const restObj =
                    monthlyData[monthKey].restaurants[adj.restaurant_name]
                restObj.adjustments += parseFloat(adj.adjustments)
                restObj.amount += parseFloat(adj.amount)
                monthlyData[monthKey].total += parseFloat(adj.value)
            })
        } else {
            orders.forEach((order) => {
                const date = new Date(order.created_at)
                if (isNaN(date)) return

                const monthKey = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`
                if (!monthlyData[monthKey]) {
                    monthlyData[monthKey] = { total: 0, restaurants: {} }
                }

                const restaurantName =
                    this.restaurants.find((r) => r.id === order.restaurant_id)
                        ?.name || '???'

                if (!monthlyData[monthKey].restaurants[restaurantName]) {
                    monthlyData[monthKey].restaurants[restaurantName] = {
                        qty: 0,
                        amount: 0,
                        losses: 0,
                        marketing: 0,
                        staff_meal: 0,
                        adjustments: 0,
                        lossesQty: 0,
                        marketingQty: 0,
                        staffMealQty: 0,
                        adjustmentsQty: 0,
                    }
                }

                const restObj =
                    monthlyData[monthKey].restaurants[restaurantName]

                if (type === 'internal') {
                    const cat = order.turnover_category
                    console.log(cat)
                    const quantity = order.consumed_qty || order.quantity
                    console.log(quantity)
                    restObj[cat] += order.total
                    const qtyKey = cat === 'staff_meal' ? 'staffMealQty' :
                                   cat === 'marketing' ? 'marketingQty' :
                                  `${cat}Qty`
                    restObj[qtyKey] += quantity
                } else {
                  restObj.amount += order.total
                  if (type === 'sales') {
                      // Pour les ventes, on utilise consumed_qty
                      restObj.qty += (order.consumed_qty || order.quantity)
                  } else {
                      // Pour les achats, on garde la quantité normale
                      restObj.qty += order.quantity
                  }
                }

                monthlyData[monthKey].total += (order.consumed_qty || order.quantity)
            })
        }

        const sortedMonths = Object.keys(monthlyData).sort()
        return {
            categories: sortedMonths.map((m) => {
                const [year, mm] = m.split('-')
                return `${mm}/${year.slice(2)}`
            }),
            data: sortedMonths.map((m) => ({
                y: monthlyData[m].total,
                restaurants: monthlyData[m].restaurants,
            })),
        }
    }

    renderChart(monthlySums, salesData, internalData, adjustmentsData) {

        const parseMonthYear = (monthYear) => {
            const [month, year] = monthYear.split('/')
            return new Date(`20${year}`, parseInt(month) - 1)
        }

        const allCategories = [
            ...new Set([
                ...monthlySums.categories,
                ...salesData.categories,
                ...internalData.categories,
                ...adjustmentsData.categories,
            ]),
        ].sort((a, b) => parseMonthYear(a) - parseMonthYear(b))

        Highcharts.chart(this.chartTarget, {
            title: {
                useHTML: true,
                text: `<i class="fa-solid fa-chart-column fa-2xl text-primary"></i> ${i18n.t('i18njs.Monthly Variation')}`,
            },
            xAxis: {
                categories: allCategories,
            },
            yAxis: {
                title: { text: i18n.t('i18njs.Quantities') },
                labels: {
                    formatter: function () {
                        return Highcharts.numberFormat(this.value)
                    },
                },
            },
            plotOptions: {
                series: {
                    cursor: 'pointer',
                    events: {
                        click: function (e) {
                            const pointIndex = e.point.index
                            const monthYear = e.point.category

                            // On récupère les données de toutes les séries pour ce mois
                            const purchaseData =
                                this.chart.series[0].data[pointIndex]
                            const salesData =
                                this.chart.series[1].data[pointIndex]
                            const internalData =
                                this.chart.series[2].data[pointIndex]
                            const adjustmentsData =
                                this.chart.series[3].data[pointIndex]


                            const allRestaurants = new Set([
                                ...Object.keys(
                                    purchaseData?.options?.restaurants || {}
                                ),
                                ...Object.keys(
                                    salesData?.options?.restaurants || {}
                                ),
                                ...Object.keys(
                                    internalData?.options?.restaurants || {}
                                ),
                                ...Object.keys(
                                    adjustmentsData?.point?.restaurants || {}
                                ),
                            ])

                            // --- TABLEAU DU CA ---
                            let caTable = `
                  <div class="fw-bold mb-1">${i18n.t('i18njs.Revenue')} (€)</div>
                  <table class="table table-sm table-bordered">
                    <thead class="table-primary">
                      <tr>
                        <th>${i18n.t('i18njs.Restaurant')}</th>
                        <th class="text-end">${i18n.t('i18njs.Purchases')}</th>
                        <th class="text-end">${i18n.t('i18njs.Sales')}**</th>
                        <th class="text-end">${i18n.t('i18njs.Losses')}*</th>
                        <th class="text-end">${i18n.t('i18njs.Marketing')}*</th>
                        <th class="text-end">${i18n.t('i18njs.Staff Meal')}*</th>
                        <th class="text-end">+/- ${i18n.t('i18njs.Stock')}</th>
                        <th class="text-end">${i18n.t('i18njs.Difference')}</th>
                      </tr>
                    </thead>
                    <tbody>
                `

                            allRestaurants.forEach((name) => {
                                const purch =
                                    purchaseData?.point?.restaurants[name]
                                        ?.amount || 0
                                const sales =
                                    salesData?.point?.restaurants[name]
                                        ?.amount || 0

                                // Internes
                                const losses =
                                    internalData?.point?.restaurants[name]
                                        ?.losses || 0
                                const marketing =
                                    internalData?.point?.restaurants[name]
                                        ?.marketing || 0
                                const staffMeal =
                                    internalData?.point?.restaurants[name]
                                        ?.staff_meal || 0

                                const adjustmentsAmount =
                                    adjustmentsData?.point?.restaurants[name]
                                        ?.amount || 0

                                const internalSum =
                                    losses + marketing + staffMeal
                                const diff =
                                    sales -
                                    internalSum -
                                    purch +
                                    adjustmentsAmount

                                caTable += `
                    <tr>
                      <td>${name}</td>
                      <td class="text-end">${Highcharts.numberFormat(purch / 100, 0, ',', ' ')}€</td>
                      <td class="text-end">${Highcharts.numberFormat(sales / 100, 0, ',', ' ')}€</td>
                      <td class="text-end">${Highcharts.numberFormat(losses / 100, 0, ',', ' ')}€</td>
                      <td class="text-end">${Highcharts.numberFormat(marketing / 100, 0, ',', ' ')}€</td>
                      <td class="text-end">${Highcharts.numberFormat(staffMeal / 100, 0, ',', ' ')}€</td>
                      <td class="text-end ${adjustmentsAmount > 0 ? 'text-success' : adjustmentsAmount < 0 ? 'text-danger' : ''}">${Highcharts.numberFormat(adjustmentsAmount / 100, 0, ',', ' ')}€</td>
                      <td class="text-end ${diff > 0 ? 'text-success' : 'text-danger'}">
                        ${Highcharts.numberFormat(diff / 100, 0, ',', ' ')}€
                      </td>
                    </tr>
                  `
                            })

                            // Totaux
                            const totalPurch = Array.from(
                                allRestaurants
                            ).reduce(
                                (sum, name) =>
                                    sum +
                                    (purchaseData?.point?.restaurants[name]
                                        ?.amount || 0),
                                0
                            )

                            const totalSales = Array.from(
                                allRestaurants
                            ).reduce(
                                (sum, name) =>
                                    sum +
                                    (salesData?.point?.restaurants[name]
                                        ?.amount || 0),
                                0
                            )
                            const totalLosses = Array.from(
                                allRestaurants
                            ).reduce(
                                (sum, name) =>
                                    sum +
                                    (internalData?.point?.restaurants[name]
                                        ?.losses || 0),
                                0
                            )

                            const totalMarketing = Array.from(
                                allRestaurants
                            ).reduce(
                                (sum, name) =>
                                    sum +
                                    (internalData?.point?.restaurants[name]
                                        ?.marketing || 0),
                                0
                            )

                            const totalStaffMeal = Array.from(
                                allRestaurants
                            ).reduce(
                                (sum, name) =>
                                    sum +
                                    (internalData?.point?.restaurants[name]
                                        ?.staff_meal || 0),
                                0
                            )
                            const totalAdjustmentsAmount = Array.from(
                                allRestaurants
                            ).reduce(
                                (sum, name) =>
                                    sum +
                                    (adjustmentsData?.point?.restaurants[name]
                                        ?.amount || 0),
                                0
                            )

                            const totalInternal =
                                totalLosses + totalMarketing + totalStaffMeal
                            const totalDiff =
                                totalSales -
                                totalInternal -
                                totalPurch +
                                totalAdjustmentsAmount

                            caTable += `
                    <tr class="table-secondary fw-bold">
                      <td>TOTAL</td>
                      <td class="text-end">${Highcharts.numberFormat(totalPurch / 100, 0, ',', ' ')}€</td>
                      <td class="text-end">${Highcharts.numberFormat(totalSales / 100, 0, ',', ' ')}€</td>
                      <td class="text-end">${Highcharts.numberFormat(totalLosses / 100, 0, ',', ' ')}€</td>
                      <td class="text-end">${Highcharts.numberFormat(totalMarketing / 100, 0, ',', ' ')}€</td>
                      <td class="text-end">${Highcharts.numberFormat(totalStaffMeal / 100, 0, ',', ' ')}€</td>
                      <td class="text-end ${totalAdjustmentsAmount > 0 ? 'text-success' : totalAdjustmentsAmount < 0 ? 'text-danger' : ''}">${Highcharts.numberFormat(totalAdjustmentsAmount / 100, 0, ',', ' ')}€</td>
                      <td class="text-end ${totalDiff > 0 ? 'text-success' : 'text-danger'}">
                        ${Highcharts.numberFormat(totalDiff / 100, 0, ',', ' ')}€
                      </td>
                    </tr>
                  </tbody>
                </table>
                `

                            // --- TABLEAU DES QUANTITÉS ---
                            let qtyTable = `
                  <div class="fw-bold mt-3 mb-1">${i18n.t('i18njs.Quantities')}</div>
                  <table class="table table-sm table-bordered">
                    <thead class="table-primary">
                      <tr>
                        <th>${i18n.t('i18njs.Restaurant')}</th>
                        <th class="text-end">${i18n.t('i18njs.Purchases')}</th>
                        <th class="text-end">${i18n.t('i18njs.Sales')}</th>
                        <th class="text-end">${i18n.t('i18njs.Losses')}*</th>
                        <th class="text-end">${i18n.t('i18njs.Marketing')}*</th>
                        <th class="text-end">${i18n.t('i18njs.Staff Meal')}*</th>
                        <th class="text-end">+/- ${i18n.t('i18njs.Stock')}</th>
                        <th class="text-end">${i18n.t('i18njs.Difference')}</th>
                      </tr>
                    </thead>
                    <tbody>
                `

                            allRestaurants.forEach((name) => {
                                const purchQty =
                                    purchaseData?.point?.restaurants[name]
                                        ?.qty || 0
                                const salesQty =
                                    salesData?.point?.restaurants[name]?.qty ||
                                    0
                                const lossesQty =
                                    internalData?.point?.restaurants[name]
                                        ?.lossesQty || 0
                                const marketingQty =
                                    internalData?.point?.restaurants[name]
                                        ?.marketingQty || 0
                                const staffMealQty =
                                    internalData?.point?.restaurants[name]
                                        ?.staffMealQty || 0
                                const adjustments =
                                    adjustmentsData?.point?.restaurants[name]
                                        ?.adjustments || 0

                                const internalQty =
                                    lossesQty + marketingQty + staffMealQty
                                const diffQty =
                                    salesQty +
                                    internalQty -
                                    purchQty +
                                    adjustments

                                qtyTable += `
                    <tr>
                      <td>${name}</td>
                      <td class="text-end">${Highcharts.numberFormat(purchQty, 0, ',', ' ')}</td>
                      <td class="text-end">${Highcharts.numberFormat(salesQty, 1, ',', ' ')}</td>
                      <td class="text-end">${Highcharts.numberFormat(lossesQty, 1, ',', ' ')}</td>
                      <td class="text-end">${Highcharts.numberFormat(marketingQty, 1, ',', ' ')}</td>
                      <td class="text-end">${Highcharts.numberFormat(staffMealQty, 1, ',', ' ')}</td>
                      <td class="text-end ${adjustments > 0 ? 'text-success' : adjustments < 0 ? 'text-danger' : ''}">${Highcharts.numberFormat(adjustments, 1, ',', ' ')}</td>
                      <td class="text-end ${diffQty > 0 ? 'text-success' : 'text-danger'}">
                        ${Highcharts.numberFormat(diffQty, 1, ',', ' ')}
                      </td>
                    </tr>
                  `
                            })

                            const totalPurchQty = Array.from(
                                allRestaurants
                            ).reduce(
                                (sum, name) =>
                                    sum +
                                    (purchaseData?.point?.restaurants[name]
                                        ?.qty || 0),
                                0
                            )

                            const totalSalesQty = Array.from(
                                allRestaurants
                            ).reduce(
                                (sum, name) =>
                                    sum +
                                    (salesData?.point?.restaurants[name]?.qty ||
                                        0),
                                0
                            )

                            // Pour les internes, on sépare par catégorie
                            const totalLossesQty = Array.from(
                                allRestaurants
                            ).reduce(
                                (sum, name) =>
                                    sum +
                                    (internalData?.point?.restaurants[name]
                                        ?.lossesQty || 0),
                                0
                            )

                            const totalMarketingQty = Array.from(
                                allRestaurants
                            ).reduce(
                                (sum, name) =>
                                    sum +
                                    (internalData?.point?.restaurants[name]
                                        ?.marketingQty || 0),
                                0
                            )

                            const totalStaffMealQty = Array.from(
                                allRestaurants
                            ).reduce(
                                (sum, name) =>
                                    sum +
                                    (internalData?.point?.restaurants[name]
                                        ?.staffMealQty || 0),
                                0
                            )
                            const totalAdjustmentsQty = Array.from(
                                allRestaurants
                            ).reduce(
                                (sum, name) =>
                                    sum +
                                    (adjustmentsData?.point?.restaurants[name]
                                        ?.adjustments || 0),
                                0
                            )
                            // Calcul du total des différences
                            const totalDiffQty =
                                totalSalesQty +
                                totalLossesQty +
                                totalMarketingQty +
                                totalStaffMealQty -
                                totalPurchQty +
                                totalAdjustmentsQty

                            // Remplacer la ligne des totaux dans qtyTable
                            qtyTable += `
                  <tr class="table-secondary fw-bold">
                    <td>TOTAL</td>
                    <td class="text-end">${Highcharts.numberFormat(totalPurchQty, 0, ',', ' ')}</td>
                    <td class="text-end">${Highcharts.numberFormat(totalSalesQty, 1, ',', ' ')}</td>
                    <td class="text-end">${Highcharts.numberFormat(totalLossesQty, 1, ',', ' ')}</td>
                    <td class="text-end">${Highcharts.numberFormat(totalMarketingQty, 1, ',', ' ')}</td>
                    <td class="text-end">${Highcharts.numberFormat(totalStaffMealQty, 1, ',', ' ')}</td>
                     <td class="text-end ${totalAdjustmentsQty > 0 ? 'text-success' : totalAdjustmentsQty < 0 ? 'text-danger' : ''}">${Highcharts.numberFormat(totalAdjustmentsQty, 1, ',', ' ')}</td>
                    <td class="text-end ${totalDiffQty > 0 ? 'text-success' : 'text-danger'}">
                      ${Highcharts.numberFormat(totalDiffQty, 1, ',', ' ')}
                    </td>
                  </tr>
                  </tbody>
                </table>
                `

                            const modalHTML = `
                    <div class="modal fade" id="detailsModal" tabindex="-1">
                        <div class="modal-dialog modal-lg" style="margin-top: 10vh;">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">${monthYear}</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                                </div>
                                <div class="modal-body" style="max-height: 70vh; overflow-y: auto;">
                                    ${caTable}
                                    ${qtyTable}
                                    <p class="text-muted small mt-2">
                                        * ${i18n.t('i18njs.the amounts indicated here are amounts in cost of goods (if at zero, this means that the cost price is not settled)')}
                                    </p>
                                    <p class="text-muted small mt-2">
                                        ** ${i18n.t('i18njs.The sales amount is expressed excluding VAT (and the reference sales price is the one that is recorded and not the one currently in the cost sheet of the sales product) and corresponds to the proportional share of this item in the total cost of the product sold')}. ${i18n.t('i18njs.For example, if a cappuccino contains coffee and milk, and coffee represents 30% of the manufacturing cost, then 30% of the turnover generated by the sale of the cappuccino will be attributed to the coffee beans item')}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                `

                            // Supprimer l'ancien modal s'il existe
                            const oldModal =
                                document.getElementById('detailsModal')
                            if (oldModal) oldModal.remove()

                            // Ajouter et afficher le nouveau modal
                            document.body.insertAdjacentHTML(
                                'beforeend',
                                modalHTML
                            )
                            const modal = new bootstrap.Modal(
                                document.getElementById('detailsModal')
                            )
                            modal.show()

                            // Nettoyer le modal quand il est fermé
                            document
                                .getElementById('detailsModal')
                                .addEventListener(
                                    'hidden.bs.modal',
                                    function () {
                                        this.remove()
                                    }
                                )
                        },
                    },
                },
            },
            tooltip: {
                shared: true,
                formatter: function () {
                    const points = this.points
                    return `<b>${points[0].key}</b><br>
              ${points
                  .map(
                      (point) =>
                          `<strong>${point.series.name}:</strong> ${Highcharts.numberFormat(point.y, 0)}`
                  )
                  .join('<br>')}
              <br><span class="text-danger"><strong><small>(${i18n.t('i18njs.Click for details')})</small></strong></span>`
                },
            },
            series: [
                // 1) Achats
                {
                    name: i18n.t('i18njs.Purchase quantities'),
                    color: '#0d6efd',
                    data: allCategories.map((cat) => {
                        const idx = monthlySums.categories.indexOf(cat)
                        const adjIdx = adjustmentsData.categories.indexOf(cat)
                        // Si pas de données mais des ajustements, créer un point à 0
                        return idx === -1
                            ? { y: 0, restaurants: {} }
                            : monthlySums.data[idx]
                    }),
                },
                {
                    name: i18n.t('i18njs.Sales quantities'),
                    color: '#dc3545',
                    data: allCategories.map((cat) => {
                        const idx = salesData.categories.indexOf(cat)
                        const adjIdx = adjustmentsData.categories.indexOf(cat)
                        return idx === -1
                            ? { y: 0, restaurants: {} }
                            : salesData.data[idx]
                    }),
                },
                {
                    name: i18n.t('i18njs.Internal quantities'),
                    color: '#f0ad4e',
                    data: allCategories.map((cat) => {
                        const idx = internalData.categories.indexOf(cat)
                        const adjIdx = adjustmentsData.categories.indexOf(cat)
                        return idx === -1
                            ? { y: 0, restaurants: {} }
                            : internalData.data[idx]
                    }),
                },
                {
                    name: i18n.t('i18njs.Stock Adjustments'),
                    color: '#28a745',
                    data: allCategories.map((cat) => {
                        const idx = adjustmentsData.categories.indexOf(cat)
                        return idx === -1
                            ? { y: 0, restaurants: {} }
                            : adjustmentsData.data[idx]
                    }),
                    visible: false,
                    showInLegend: true,
                    enableMouseTracking: false,
                },
            ],
            credits: { enabled: false },
        })
    }
}
